import contracts from "../config/contracts";
import BUSDSymbol from "./../assets/images/busd_logo.png";
import USDTSymbol from "./../assets/images/usdt.png";
import HNTRSymbol from "./../assets/images/hntr.png";

export const Tokens = {
  // [contracts["BUSD"].toLowerCase()]: { icon: BUSDSymbol, symbolName: "BUSD" },
  [contracts["USDT"].toLowerCase()]: { icon: USDTSymbol, symbolName: "USDT" },
  [contracts["HNTR"].toLowerCase()]: { icon: HNTRSymbol, symbolName: "HNTR" }
};
