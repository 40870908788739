import React from "react";
import { Link } from "react-router-dom";
import { Tokens } from "../../helpers/tokensToSymbol";


import moment from "moment";


function CollectionList(props) {

  return (
    <Link to={`/NFTdetails/${props.nft?.id}`}>
      <div className='collection_items'>
        <div className="collection__img">

          {(props?.nft?.previewImg || props?.nft?.image) ?
            <img
              src={props.nft?.previewImg ? props.nft?.previewImg : props.nft?.image}
              className='img-fluid items_img w-100 '
              alt=''
              onError={(e) => {
                e.target.src = props.nft?.previewImg ? props.nft?.previewImg : "../img/collections/list4.png"
              }}
            />
            : <div className="blur_img_div h-100">
              <img
                src={props?.nft?.collectionData[0]?.logoImage}
                className='img-fluid items_img w-100 blur_img'
                alt='nft'
                onError={(e) => {
                  e.target.src = "../img/collections/list4.png";
                }}
              />
              <span className="no_img">No Image</span>
            </div>
          }


        </div>
        <div className='coll_itemstext'>
          <div className='collection_row mb-3'>
            <div className='collection_col'>
              <span>{props.nft?.collectionName} Collection</span>
              <h6 data-toggle="tooltip" title={props.nft?.name}>{props.nft?.name?.length > 50 ? props.nft?.name?.slice(0, 50) + "..." : props.nft?.name}</h6>
            </div>
            <div className='collection_col text-end'>
              <span>Price</span>
              <span className='col_proiduct_price  d-flex justify-content-end align-items-center'>
                <div className="token_img">
                  {props.nft?.paymentToken && <img src={Tokens[props?.nft?.paymentToken?.toLowerCase()]?.icon} alt="payment token symbol" />}
                </div>
                <div className="ml-3">
                  {props.nft?.price}
                </div>
              </span>

            </div>
          </div>
          <div className='collection_row align-items-center'>
            <div className='collection_col'>
              <Link className='buy_btn' to={`/NFTdetails/${props.nft?.id}`}>
                {props.nft?.saleType === 3
                  ? "Buy Now"
                  : ((props.nft?.saleType === 4) && moment.utc(props.nft?.deadline * 1000).local().format() >= moment(new Date()).format())
                    ? "Place Bid" : moment.utc(props.nft?.deadline * 1000).local().format() < moment(new Date()).format() ? "Auction Ended"
                      : "View"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CollectionList;
