import React from 'react'

function wallet() {
  return (
    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_58_781)">
        <path d="M28.95 8.66686H27.3333V4.5002C27.3333 4.27918 27.2455 4.06722 27.0893 3.91094C26.933 3.75466 26.721 3.66686 26.5 3.66686H6.5C6.27899 3.66686 6.06703 3.57906 5.91074 3.42278C5.75446 3.2665 5.66667 3.05454 5.66667 2.83353C5.66667 2.61251 5.75446 2.40055 5.91074 2.24427C6.06703 2.08799 6.27899 2.0002 6.5 2.0002H26.1667C26.3877 2.0002 26.5996 1.9124 26.7559 1.75612C26.9122 1.59984 27 1.38788 27 1.16686C27 0.945848 26.9122 0.733887 26.7559 0.577606C26.5996 0.421326 26.3877 0.333528 26.1667 0.333528H6.5C6.17824 0.326895 5.85833 0.383718 5.55854 0.500751C5.25875 0.617785 4.98496 0.792735 4.7528 1.01561C4.52064 1.23848 4.33466 1.50491 4.20549 1.79968C4.07633 2.09444 4.0065 2.41177 4 2.73353V20.2335C4.00109 20.6862 4.09147 21.1342 4.26596 21.5519C4.44045 21.9696 4.69563 22.3487 5.01688 22.6677C5.33812 22.9866 5.71913 23.239 6.13807 23.4105C6.55702 23.5819 7.00567 23.6691 7.45833 23.6669H26.5C26.721 23.6669 26.933 23.5791 27.0893 23.4228C27.2455 23.2665 27.3333 23.0545 27.3333 22.8335V18.6669H28.95C29.0552 18.6748 29.1609 18.6612 29.2606 18.6269C29.3604 18.5926 29.4521 18.5383 29.5301 18.4673C29.6081 18.3963 29.6708 18.3101 29.7143 18.214C29.7579 18.1179 29.7814 18.014 29.7833 17.9085V9.5752C29.7861 9.34618 29.7014 9.12473 29.5466 8.95597C29.3918 8.7872 29.1784 8.68381 28.95 8.66686ZM28.1667 17.0002H21.0167C20.1626 16.9698 19.3556 16.6017 18.7728 15.9767C18.19 15.3517 17.8791 14.5209 17.9083 13.6669C17.8791 12.8128 18.19 11.982 18.7728 11.3571C19.3556 10.7321 20.1626 10.3639 21.0167 10.3335H28.1667V17.0002Z" fill="white"/>
        <path d="M21.5334 14.8496C22.2238 14.8496 22.7834 14.29 22.7834 13.5996C22.7834 12.9093 22.2238 12.3496 21.5334 12.3496C20.8431 12.3496 20.2834 12.9093 20.2834 13.5996C20.2834 14.29 20.8431 14.8496 21.5334 14.8496Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_d_58_781" x="0" y="0.333008" width="33.7834" height="31.334" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_58_781"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_58_781" result="shape"/>
        </filter>
        </defs>
    </svg>
  )
}

export default wallet

