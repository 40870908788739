import React, { useState, useEffect } from "react";
import Slider from "./slick-loader/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import {
  getNFTs,
  getPrice,
} from "../../helpers/getterFunctions";
import "./../components-css/App.css";
import { convertToEth } from "../../helpers/numberFormatter";
import { Tokens } from "../../helpers/tokensToSymbol";
import moment from "moment";

function Testimonials() {


  var settings = {
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    ltr: true,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="container">

      <Slider {...settings}>

        <div className="testimonial-cards d-flex justify-content-center">
          <div style={{ width: '296px' }}>

            <div style={{ borderLeft: '5px solid #ef981d', padding: '5px 20px', borderRadius: '5px', height: '100%', MaxWidth: '256px' }}>
              <h3 style={{ borderBottom: '1px solid #ef981d', paddingBottom: '10px',minHeight:'181px', color: '#fff', fontWeight: '700', fontSize: '22px', textAlign: 'left' }}>
                “The level of attention to the detail is crazy, was easy login and purchase using email.”</h3>
              <div>
                <div style={{ textAlign: "left", fontSize: '18px', color: '#fff' }}><ul >
                  <li style={{ paddingLeft: '0px' }}>James K</li></ul></div>
              </div>
            </div>
          </div>

        </div>

        <div className="testimonial-cards d-flex justify-content-center">
          <div style={{ width: '296px' }}>

            <div style={{ borderLeft: '5px solid #ef981d', padding: '5px 20px', borderRadius: '5px', height: '100%', MaxWidth: '256px' }}>
              <h3 style={{ borderBottom: '1px solid #ef981d', paddingBottom: '10px',minHeight:'181px', color: '#fff', fontWeight: '700', fontSize: '22px', textAlign: 'left' }}>
                “Easy-peasy transaction. Very happy with these and waited and waited. Nice one team - let's get some more out there now as I want a full armoury.”</h3>
              <div>
                <div style={{ textAlign: "left", fontSize: '18px', color: '#fff' }}><ul >
                  <li style={{ paddingLeft: '0px' }}>NoodleFringe</li></ul></div>
              </div>
            </div>
          </div>

        </div>

        <div className="testimonial-cards d-flex justify-content-center">
          <div style={{ width: '296px' }}>

            <div style={{ borderLeft: '5px solid #ef981d', padding: '5px 20px', borderRadius: '5px', height: '100%', MaxWidth: '256px' }}>
              <h3 style={{ borderBottom: '1px solid #ef981d', paddingBottom: '10px',minHeight:'181px', color: '#fff', fontWeight: '700', fontSize: '22px', textAlign: 'left' }}>
                “NFT guns in games can’t wait, that’s a winner. Help was available when I needed. Dig Arms is going places"</h3>
              <div>
                <div style={{ textAlign: "left", fontSize: '18px', color: '#fff' }}><ul >
                  <li style={{ paddingLeft: '0px' }}>ConnorB</li></ul></div>
              </div>
            </div>
          </div>

        </div>

        <div className="testimonial-cards d-flex justify-content-center">
          <div style={{ width: '296px' }}>

            <div style={{ borderLeft: '5px solid #ef981d', padding: '5px 20px', borderRadius: '5px', height: '100%', MaxWidth: '256px' }}>
              <h3 style={{ borderBottom: '1px solid #ef981d', paddingBottom: '10px',minHeight:'181px', color: '#fff', fontWeight: '700', fontSize: '22px', textAlign: 'left' }}>
                I got mine. Nice and easy. Used a desktop and scanned wallet connect with the phone.
              </h3>
              <div>
                <div style={{ textAlign: "left", fontSize: '18px', color: '#fff' }}><ul >
                  <li style={{ paddingLeft: '0px' }}>MarioLu</li></ul></div>
              </div>
            </div>
          </div>

        </div>

        <div className="testimonial-cards d-flex justify-content-center">
          <div style={{ width: '296px' }}>

            <div style={{ borderLeft: '5px solid #ef981d', padding: '5px 20px', borderRadius: '5px', height: '100%', MaxWidth: '256px' }}>
              <h3 style={{ borderBottom: '1px solid #ef981d', paddingBottom: '10px',minHeight:'181px', color: '#fff', fontWeight: '700', fontSize: '22px', textAlign: 'left' }}>
                I’m fairly new to NFT’s and the customer support is top-notch and has helped me navigate my wallet connection.
              </h3>
              <div>
                <div style={{ textAlign: "left", fontSize: '18px', color: '#fff' }}><ul >
                  <li style={{ paddingLeft: '0px' }}>Boss Dog</li></ul></div>
              </div>
            </div>
          </div>

        </div>

        <div className="testimonial-cards d-flex justify-content-center">
          <div style={{ width: '296px' }}>

            <div style={{ borderLeft: '5px solid #ef981d', padding: '5px 20px', borderRadius: '5px', height: '100%', MaxWidth: '256px' }}>
              <h3 style={{ borderBottom: '1px solid #ef981d', paddingBottom: '10px',minHeight:'181px', color: '#fff', fontWeight: '700', fontSize: '22px', textAlign: 'left' }}>
                Super easy - paid with my card, no hassle. Now just eagerly waiting for the reveal.
              </h3>
              <div>
                <div style={{ textAlign: "left", fontSize: '18px', color: '#fff' }}><ul >
                  <li style={{ paddingLeft: '0px' }}>James K</li></ul></div>
              </div>
            </div>
          </div>

        </div>



      </Slider>
    </div>

  );
}

export default Testimonials;
