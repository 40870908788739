const contracts = {
  CREATOR_PROXY: process.env.REACT_APP_NETWORK_ID == 97 ? "0xd26675128614b6831f3ef30Cf0ae209eDEDd19C3" : "0xeDbD045bd0B8721d7dB2b0941084251a12E1A286",
  MARKETPLACE: process.env.REACT_APP_NETWORK_ID == 97 ? "0x15A99D5ec9d7292646a83F4233f638B05363a381" : "0xde7685Ae1299270358D176f7e9a5B5414D3cD834",
  BNB: "0x0000000000000000000000000000000000000000",
  USDT: process.env.REACT_APP_NETWORK_ID == 97 ? "0x5cb044feFEb018AdBCa76aF8Df7634e515Ed5291" : "0x55d398326f99059fF775485246999027B3197955",
  BARRETT: process.env.REACT_APP_NETWORK_ID == 97 ? "0x056f48E1eb35dbfF7cA4Bb807f48B63165E0A9bB" : "0x9f0eE047Be24c3b65A208D6E026B45acB74610ca",
  HNTR: process.env.REACT_APP_NETWORK_ID == 97 ? "0x2D12f78B3098a3F44E7d080431C246920A2ed7b3" : "0x83451A4E3585FdA74FEb348Ad929f2C4cA189660",
  METAMARINES: process.env.REACT_APP_NETWORK_ID == 97 ? "0xA98Da01942397Ba10B3BE0cd9Ce24f902299C5EC" : "0xE554313B4afb12f2f0887361Bd5C9Ee1Bdf230ed"
};

export default contracts;
