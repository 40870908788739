import { ethers } from "ethers";
import contracts from "../config/contracts";

export const GENERAL_TIMESTAMP = 1703933534;
export const GENERAL_DATE = "30/12/2023";
export const CURRENCY = "ETH";
export const MAX_ALLOWANCE_AMOUNT = ethers.constants.MaxInt256;
export const TOKEN_OPTIONS = [
    // { key: contracts["BUSD"].toLowerCase(), value: "BUSD" },
    { key: contracts["USDT"].toLowerCase(), value: "USDT" },
    // { key: contracts["HNTR"].toLowerCase(), value: "HNTR" }
];

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const MAX_WHITELIST_BUY_PER_USER = 20
export const perPageCount = 12
export const NOTIFICATION_DELAY = 3000