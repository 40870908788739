import { React, useEffect, useState } from "react";
import Footer from "../../components/footer";
import "../../components-css/App.css";
import {
	exportInstance,
	FetchInstance,
} from "../../../apiServices";
import { getCurrentProvider, getUsersTokenBalance } from "../../../helpers/getterFunctions";
import metamarineAbi from "./../../../config/abis/metamarine.json";
import erc20Abi from "./../../../config/abis/erc20.json";
import { useCookies } from "react-cookie";
import BigNumber from "bignumber.js";
import {
	NOTIFICATION_DELAY,
} from "../../../helpers/constants";
import { WalletConditions } from "../../components/WalletConditions";
import eventEmitter from "../../../events/events";
import Spinner from "../../components/Spinner";
import PopupModal from "../../components/AccountModal/popupModal";
import PopupModalStripe from "../../components/AccountModal/popupModalStripe";
import { convertToEth } from "../../../helpers/numberFormatter";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
	getAllCountries,
	getAllStates,
	getUserQuantity,
} from "../../../apiServices";
import bodyBGImg from "./../../../assets/images/background.jpg";
import NFTPlaceHolder from "./../../../assets/Minting/barretMintPlaceholder.png";
import ProgressModal from "../../components/AccountModal/ProgressModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import contracts from "../../../config/contracts";
import { web3authInstance } from "../../menu/header";
import { ethers } from "ethers";
import Logo from "./../../../assets/images/logo.svg";
import BGImg from "./../../../assets/Minting/barretMintingCropped.jpg"
import "./minting.css"
import { useWalletConnectClient } from "../../../contexts/ClientContext";
import { switchNetwork } from "../../../helpers/sendFunctions";

const contractAddress = contracts.BARRETT;
const catId = process.env.REACT_APP_BARRETT_CATID;
const NFT_PRICE_IN_DOLLAR = process.env.REACT_APP_BARRETT_PRICE_IN_DOLLAR;
const CURRENCY = "USD";
const SYMBOL = "$";

var bgImgStyle = {
	backgroundImage: `url(${bodyBGImg})`,
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	backgroundPositionX: "center",
	backgroundPositionY: "center",
	backgroundColor: "#000",
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);



function Barrett() {
	const [toggle, setToggle] = useState(false);
	const [contract, setContract] = useState();
	const [cookies] = useCookies([]);
	const [currQty, setCurrQty] = useState(1);
	const [catInfo, setCatInfo] = useState();
	const [currentUser, setCurrentUser] = useState();
	const [loading, setLoading] = useState(false);
	const [isShowModal, setIsShowModal] = useState(false);
	const [reload, setReload] = useState(false);
	const [isShowPopup, setIsShowPopup] = useState(false);
	const [price, setPrice] = useState(0)
	const [popupdataData, setPopupdataData] = useState([]);
	const [status, setStatus] = useState(false)
	const [userData, setUserData] = useState({
		fullName: "",
		emailAddress: "",
		country: "",
		state: "",
		postalCode: "",
	});
	const [countryList, setCountryList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [closeDisabled, setCloseDisabled] = useState(true);
	const [totalMintedByUser, setTotalMintedByUser] = useState(0);
	const [totalItems, setTotalItems] = useState(0);
	const [maxperWallet, setMaxperWallet] = useState(0);
	const [maxNFTs, setMaxNFTs] = useState(0);
	const [userQtyDB, setUserQtyDB] = useState(0);
	const [totalSupply, setTotalSupply] = useState(0);
	const [orderCompleted, setOrderCompleted] = useState(0);
	const [showAlert, setShowAlert] = useState("")
	const [totalMintedPerCat, setTotalMintedPerCat] = useState(0)
	const [maxSupply, setMaxSupply] = useState("2222")

	const { web3Provider } = useWalletConnectClient()
	const handleShowAlert = (error) => {
		setShowAlert(error);
	}

	const toggleLoader = (isLoading) => {
		if (isLoading === "completed") {
			setCurrQty(1);
			setOrderCompleted(1)
			setLoading(false);
		}
		if (isLoading === "end") {
			setLoading(false);
			console.log("Loader stopped")
		}

	};

	eventEmitter.on("processStart", toggleLoader);

	useEffect(() => {
		setCurrentUser(cookies.selected_account);
		const fetch = async () => {
			if (cookies.selected_account) {
				try {
					const countries = await getAllCountries();
					setCountryList(countries);
				} catch (err) {
					console.log("Error in getting userinfo", err);
				}
			}
		};
		fetch();
	}, [cookies.selected_account]);

	useEffect(() => {
		const fetch = async () => {
			console.log("currUser", currentUser);
			await getContractDetails();
			let qtyReq = {
				walletAddress: currentUser,
				contractAddress: contractAddress,
			};
			if (currentUser) {
				const userQty = await getUserQuantity(qtyReq);
				console.log("userinfo userQty", userQty);
				setUserQtyDB(userQty);
			}
		};
		fetch();
	}, [reload, currentUser]);

	useEffect(() => {
		const updateQty = async () => {
			let qtyAllowed = parseInt(maxperWallet) - (parseInt(userQtyDB) + parseInt(totalMintedByUser));
			console.log("qtyAllowed before", qtyAllowed)
			let leftItems = parseInt(totalItems) - parseInt(totalSupply);
			if (parseInt(qtyAllowed) > parseInt(leftItems)) {
				qtyAllowed = leftItems;
			}
			if (qtyAllowed <= 0) {
				qtyAllowed = 0;
				// setCurrQty(0)
			}
			setMaxNFTs(qtyAllowed);
			console.log("qtyAllowed after", qtyAllowed)
		};
		updateQty();
	}, [userQtyDB, totalMintedByUser, maxperWallet]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const updateState = async (val) => {
		try {
			console.log("val", val);
			let reqData = { country: val };
			setLoading(true);
			let stateDB = await getAllStates(reqData);
			if (stateDB) {
				setStateList(stateDB);
			} else {
				setStateList([]);
			}
			setLoading(false);
		} catch (err) {
			console.log("err", err);
			setLoading(false);
		}
	};

	const getContractDetails = async () => {
		try {
			setLoading(true);
			console.log("contractAddress", contractAddress);
			let contractInstance = await FetchInstance(
				contractAddress,
				metamarineAbi
			);
			console.log("contract instance 223", contractInstance);
			let categoryInfo;
			try {
				categoryInfo = await contractInstance.categories(catId);
			} catch (errcategoryInfo) {
				console.log("errCat", errcategoryInfo);
				setPrice("TBA")
				setTotalItems(2222)
			}

			let totalMinted;
			try {
				totalMinted = await contractInstance.totalTokensMintedPerCategory(
					catId
				);
				setTotalMintedPerCat(totalMinted)
			} catch (errtotalMinted) {

				console.log("totalMinted", errtotalMinted);

			}

			let mintedByUser;
			try {
				if (currentUser) {
					console.log("currentUser ", currentUser);
					mintedByUser = await contractInstance.balanceOf(currentUser);
				}
			} catch (errmintedByUser) {

				console.log("errmintedByUser ", errmintedByUser);
				setPrice("TBA")
				setTotalItems(2222)
			}
			try {

				let _maxSupply = await contractInstance.maxSupply();
				setMaxSupply(_maxSupply?.toString())

			} catch (errmintedByUser) {

				setMaxSupply(2222)
			}

			try {
				let _totalSupply = await contractInstance.totalSupply();
				setTotalSupply(_totalSupply?.toString());
			} catch (errmintedByUser) {
				setTotalSupply(0)
				setPrice("TBA")
				setTotalItems(2222)
				console.log("errmintedByUser ", errmintedByUser);
			}

			if (mintedByUser) {
				mintedByUser = mintedByUser?.toString()
			} else {
				mintedByUser = 0
			}

			console.log("categoryInfo", categoryInfo);
			console.log("totalMinted", totalMinted?.toString());
			console.log("mintedByUser", mintedByUser?.toString());

			console.log("hereree", contractInstance)
			setPrice(convertToEth(categoryInfo?.price?.toString()).toString())
			setContract(contractInstance);
			setTotalMintedByUser(mintedByUser?.toString());
			setTotalItems(categoryInfo?.categoryTokenCap?.toString());
			setMaxperWallet(categoryInfo?.maxPerAddress?.toString());
			if (
				categoryInfo.isActive &&
				Number(categoryInfo?.endTime) > Date.now() / 1000 &&
				Number(categoryInfo?.startTime) < Date.now() / 1000
			) {
				setStatus(true);
			} else {
				setStatus(false);
			}
			setCatInfo(categoryInfo);

			setLoading(false);
		} catch (err) {
			console.log("err", err);
			setPrice("TBA")
			setTotalItems(0)
			setTotalMintedByUser(0)

			setLoading(false);
		}
	};

	const whitelistLogic = async () => {
		try {
			if (catInfo.isPrivate) {
				let isWhitelisted = await contract.allowMint(currentUser, catId);
				console.log("is whitelisted", isWhitelisted);
				if (isWhitelisted) return true;
				else return false;
			} else {
				return true;
			}
		} catch (err) {
			console.log("err", err);
			return false;
		}
	};

	const approvalLogic = async () => {
		try {
			console.log("payment token");
			let paymentToken = await catInfo?.paymentToken;

			console.log("payment token", paymentToken);
			let tokenContract = await exportInstance(paymentToken, erc20Abi, await getCurrentProvider(web3Provider));
			let allowance = await tokenContract.allowance(
				currentUser,
				contractAddress
			);

			console.log(
				"cat info",
				catInfo,
				new BigNumber(catInfo?.price?.toString())
					.multipliedBy(currQty)
					.toFixed(),
				allowance.toString()
			);
			if (
				new BigNumber(allowance?.toString()).isLessThan(new BigNumber(catInfo?.price?.toString())
					.multipliedBy(currQty))

			) {
				let es = await tokenContract.estimateGas.approve(
					contractAddress,
					new BigNumber(catInfo?.price?.toString())
						.multipliedBy(currQty).toFixed()

				);
				let priceLimit = new BigNumber(es.toString()).plus(
					new BigNumber(es.toString()).multipliedBy(0.1)
				);
				let options = {
					from: currentUser,
					gasLimit: Math.ceil(parseFloat(priceLimit.toString())),
					value: 0,
				};
				const web3authProvider = await getCurrentProvider(web3Provider)
				// const web3authProvider = localStorage.getItem("web3AuthProvider")
				console.log("init modal is called---->", web3authProvider);


				let provider = new ethers.providers.Web3Provider(web3authProvider);
				let bnbBal = await provider.getBalance(currentUser)
				if (new BigNumber(bnbBal?.toString()).isLessThan(new BigNumber(Math.ceil(parseFloat(priceLimit.toString()))?.toString()))) {
					NotificationManager.error("Insufficient BNB for gas");
					return false
				}
				let res = await tokenContract.approve(
					contractAddress,
					new BigNumber(catInfo?.price?.toString())
						.multipliedBy(currQty).toFixed()
					,
					options
				);
				res = await res.wait();

				let allowanceNew = await tokenContract.allowance(
					currentUser,
					contractAddress
				);


				if (
					new BigNumber(allowanceNew?.toString()).isLessThan(new BigNumber(catInfo?.price?.toString())
						.multipliedBy(currQty))

				) {
					NotificationManager.error("Insufficient Allowance")
					return false
				}
			}
			return true;
		} catch (err) {
			console.log("err", err);
			return false;
		}
	};

	const balanceChecks = async () => {
		if (currentUser) {
			let hntrbal = await getUsersTokenBalance(currentUser, contracts.HNTR);
			console.log("balance", hntrbal)
			const web3authProvider = await getCurrentProvider(web3Provider);
			// const web3authProvider = localStorage.getItem("web3AuthProvider")
			console.log("init modal is called---->", web3authProvider);


			let provider = new ethers.providers.Web3Provider(web3authProvider);
			let bnbBal = await provider.getBalance(currentUser)

			if (Number(convertToEth(hntrbal?.toString())) === 0) {
				NotificationManager.error("Insufficient HNTR balance")
				return false
			}
			if (Number(convertToEth(bnbBal?.toString())) === 0) {
				NotificationManager.error("Insufficient BNB balance")
				return false
			}
			if (Number(maxNFTs) <= Number(totalMintedByUser)) {
				NotificationManager.error("Max Per User Reached")
				return false
			}
			console.log("balance in bnb", bnbBal.toString())
			return true
		}
	}

	const mintTokens = async () => {
		try {
			setLoading(true);
			if (Number(totalMintedPerCat) + Number(currQty) > Number(totalItems)) {
				NotificationManager.error("Max Category Cap Reached", "",
					NOTIFICATION_DELAY)
				setLoading(false);
				return false
			}
			let balCheck = await balanceChecks()
			if (!balCheck) {
				setLoading(false);
				return false
			}
			let isWhitelisted = await whitelistLogic();
			if (!isWhitelisted) {
				NotificationManager.error(
					"User is not whitelisted",
					"",
					NOTIFICATION_DELAY
				);
				setLoading(false);
				return;
			}
			let res = await approvalLogic();
			if (res === false) {
				setLoading(false);
				return;
			}


			let _contract = await exportInstance(contractAddress,
				metamarineAbi, await getCurrentProvider(web3Provider))
			console.log("_contract", _contract)
			let es = await _contract.estimateGas.mintTokens(catId, currQty);
			console.log("estimated gas", es);
			let priceLimit = new BigNumber(es.toString()).plus(
				new BigNumber(es.toString()).multipliedBy(0.5)
			);
			let options = {
				from: currentUser,
				gasLimit: Math.ceil(parseFloat(priceLimit.toString())),
				value: 0,
			};
			const web3authProvider = await getCurrentProvider(web3Provider);
			let provider = new ethers.providers.Web3Provider(web3authProvider);
			let bnbBal = await provider.getBalance(currentUser)
			if (new BigNumber(bnbBal?.toString()).isLessThan(new BigNumber(Math.ceil(parseFloat(priceLimit.toString()))?.toString()))) {
				NotificationManager.error("Insufficient BNB for gas");
				setLoading(false);
				return false
			}
			let res1
			try {
				res1 = await _contract.mintTokens(catId, currQty, options);
				console.log("response", res1);
			}

			catch (err) {
				console.log("err", err)
			}
			res1 = await res1.wait();
			setOrderCompleted(1)
			setIsShowModal(1)
			NotificationManager.success("NFT Purchased Successfully")
			setLoading(false);
			// setCurrQty(1);
			// setReload(!reload);
		} catch (err) {
			console.log("err", err);
			setLoading(false);
		}
	};

	return (
		<div style={bgImgStyle}>
			{isShowPopup ? (
				<ProgressModal
					datas={popupdataData}
					onRequestClose={() => {
						setIsShowPopup(!isShowPopup);
						setReload(!reload);
						setCurrQty(1);
					}}
					disabled={closeDisabled}
				/>
			) : (
				""
			)}
			{loading ? <Spinner /> : ""}
			{showAlert === "chainId" ? <PopupModal content={<div className='popup-content1'>
				<div class="model_data">
					<div className='bid_user_details mb-4'>
						<img src={Logo} alt='' />
						<div className='bid_user_address'>
							<div >
								<div className="mr-3 text-white">Required Network ID:</div>
								<span className="adr">
									{process.env.REACT_APP_NETWORK_ID}
								</span>
							</div>
							<div >
								<div className="mr-3 text-white">Required Network Name:</div>
								<span className="adr">
									{process.env.REACT_APP_NETWORK}
								</span>
							</div>
						</div>
					</div>
					<button
						className='btn-main mt-2 mb-1' onClick={async () => {
							await switchNetwork()
							setShowAlert("")
						}}>
						{"Switch Network"}
					</button>
				</div>
			</div>} handleClose={() => { setShowAlert(!showAlert) }} /> :
				showAlert === "account" ? <PopupModal content={
					<div class="model_data">
						<div className='popup-content1'>
							<div className='bid_user_details mb-4'>
								<img src={Logo} alt='' />
								<div className='bid_user_address align-items-center'>
									<div>
										<span className="adr text-muted">
											{currentUser}
										</span>
										<span className='badge badge-success'>Connected</span>
									</div>
									<h4 className="mb-3">Please switch to connected wallet address or click logout to continue with the current wallet address by disconnecting the already connected account.</h4>
								</div>

								<button
									className='btn-main mt-2 mb-1' onClick={() => { eventEmitter.emit("disconnectWallet") }}>
									{"Logout"}
								</button>
							</div>
						</div>
					</div>} handleClose={() => { setShowAlert(!showAlert) }} /> :
					showAlert === "locked" ? <PopupModal content={<div className='popup-content1'>
						<div class="model_data">
							<div className='bid_user_details mb-4'>
								<img src={Logo} alt='' />
								<div className='bid_user_address align-items-center'>
									<div>
										<span className="adr text-muted">
											{currentUser}
										</span>
										<span className='badge badge-success'>Connected</span>
									</div>
								</div>
								<h4 className="mb-3">Your wallet is locked. Please unlock your wallet and connect again.</h4>
							</div>
							<button
								className='btn-main mt-2 mb-1' onClick={() => {
									eventEmitter.emit("disconnectWallet")
								}}>
								Connect Wallet
							</button>
						</div>
					</div>} handleClose={() => { setShowAlert(!showAlert) }} /> :
						showAlert === "notConnected" ? <PopupModal content={<div className='popup-content1'>
							<div class="model_data">
								<div className='bid_user_details my-4'>
									<img src={Logo} alt='' />
									<h4 className="mb-3">Please connect your wallet. </h4>
								</div>
								<button
									className='btn-main mt-2 mb-1' onClick={() => {
										setShowAlert("")
										eventEmitter.emit("connectWallet")
									}}>
									Connect Wallet
								</button>
							</div>
						</div>} handleClose={() => { setShowAlert(!showAlert) }} /> : ""}
			<section

			>
				<img src={BGImg} className="img-fluid multimintingPageBanner" ></img>
			</section>
			<section className="collection_info">
				<div className="container">
					<ul className="collection_status mt-5 mb-5">
						<li>
							{console.log("totalSupply", totalSupply, totalItems, price == "NaN", isNaN(price))}
							<h4>
								{totalSupply}/{maxSupply ? maxSupply?.toString() : 2222}
							</h4>
							{/* <h4>616</h4> */}
							<p>Items Minted</p>
						</li>
						<li>
							<h4>{price && price != "NaN" ? price : "TBA"}</h4>
							{/* <h4>TBA</h4> */}
							<p>HNTR</p>
						</li>
						<li>
							<h4>{NFT_PRICE_IN_DOLLAR}</h4>
							{/* <h4>TBA</h4> */}
							<p>USD</p>
						</li>

						<li>
							<h4>{status ? "Active" : "Inactive"}</h4>
							{/* <h4>Inactive</h4> */}
							<p>Status</p>
						</li>
					</ul>
					<div className="collection_description text-center  mt-5">
						{!toggle ? (
							<p>Claim your stake in digital history with the world's first IP licensed firearms NFT! The Barrett M82A1 NFT, meticulously crafted from the original 3D CAD files, promises exclusive gaming benefits within the Digital Arms ecosystem. Limited to just 2222 pieces, including only 101 coveted Legendary gold skin variants. Purchase made easy with HNTR tokens or credit card. Score your golden ticket in this digital revolution now!

							</p>

						) : (
							<p>

								Claim your stake in digital history with the world's first IP licensed firearms NFT! The Barrett M82A1 NFT, meticulously crafted from the original 3D CAD files, promises exclusive gaming benefits within the Digital Arms ecosystem. Limited to just 2222 pieces, including only 101 coveted Legendary gold skin variants. Purchase made easy with HNTR tokens or credit card. Score your golden ticket in this digital revolution now!
								<div className="row row-cols-1 mt-4 row-cols-md-3">
									<div className="col mb-4 d-flex align-items-stretch">
										<div className="card customBG">
											<div className="card-body">
												<h4 className="card-title">Digital Milestone</h4>
												<p className="card-text">The Barrett NFTs are a pioneering release in the realm of licensed digital firearm assets, marking a significant moment in the history of NFT growth.</p>
											</div>
										</div>
									</div>
									<div className="col mb-4 d-flex align-items-stretch">
										<div className="card customBG">
											<div className="card-body">
												<h4 className="card-title">Utility and Gaming Advantages</h4>
												<p className="card-text">These NFTs aren't just collectibles; they boost the gaming experience and highlight the powerful intersection of blockchain and gaming.</p>
											</div>
										</div>
									</div>
									<div className="col mb-4 d-flex align-items-stretch">
										<div className="card customBG">
											<div className="card-body">
												<h4 className="card-title">Limited Edition, Unlimited Potential</h4>
												<p className="card-text">With a limited supply, especially of the prestigious gold-skinned variants, these NFTs have a unique allure that could drive future value.</p>
											</div>
										</div>
									</div>
									<div className="col mb-4 d-flex align-items-stretch">
										<div className="card customBG">
											<div className="card-body">
												<h4 className="card-title">Future-Proof Investment</h4>
												<p className="card-text">Buying a Barrett NFT is investing in a rapidly evolving digital future where the physical and digital converge in exciting new ways.</p>
											</div>
										</div>
									</div>
									<div className="col mb-4 d-flex align-items-stretch">
										<div className="card customBG">
											<div className="card-body">
												<h4 className="card-title">Exclusive Access</h4>
												<p className="card-text">As a Barrett NFT holder, enjoy special perks within the Digital Arms ecosystem, from boosted staking APY to unique content and events.</p>
											</div>
										</div>
									</div>
									<div className="col mb-4 d-flex align-items-stretch">
										<div className="card customBG">
											<div className="card-body">
												<h4 className="card-title">Witnessing the Future</h4>
												<p className="card-text">Owning a Barrett NFT is experiencing first-hand the evolution and potential of digital collectibles and licensed digital assets.</p>
											</div>
										</div>
									</div>
									<div className="col mb-4 d-flex align-items-stretch">
										<div className="card customBG">
											<div className="card-body">
												<h4 className="card-title">Cross-Platform Utility</h4>
												<p className="card-text">These NFTs can be utilized across various gaming platforms, adding to their versatility and value.</p>
											</div>
										</div>
									</div>
									<div className="col mb-4 d-flex align-items-stretch">
										<div className="card customBG">
											<div className="card-body">
												<h4 className="card-title">Join the Visionary Community</h4>
												<p className="card-text">Be part of a dynamic group of forward-thinking gamers and investors, united by a shared vision of the digital landscape.</p>
											</div>
										</div>
									</div>
									<div className="col mb-4 d-flex align-items-stretch">
										<div className="card customBG">
											<div className="card-body">
												<h4 className="card-title">Market Resilience</h4>
												<p className="card-text">Despite market fluctuations, NFTs and blockchain technology are here to stay and grow. Your Barrett NFT is a ticket to this thriving digital market.</p>
											</div>
										</div>
									</div>
								</div>
							</p>
						)}

						{
							<span className="top_arrow">
								<img
									alt=""
									src={!toggle ? "../img/bottom_arrow.png" : "../img/top_arrow.png"}
									onClick={() => setToggle((toggle) => !toggle)}
									className="img-showMore less img-fluid"
								/>
							</span>
						}
					</div>

				</div>
			</section>
			<section className="collection_list mb-5 pb-5">
				<div className="container">
					<div className="event_slider">
						<div className="mintevent text-center">
							{/* <div className="start_btn stamintFunctionbtn">{catInfo?.isActive ? "Active" : "TBA"}</div> */}
							<div className="start_btn stamintFunctionbtn">
								{status ? "Active" : "Inactive"}
							</div>
							<h4>Mint Event</h4>
							<div className="da_img mb-3">
								<img src={NFTPlaceHolder} alt="" />
							</div>
							{!currentUser && (
								<button
									className="connect_wallet_btn mb-4 disabled"
									onClick={() => {
										eventEmitter.emit("connectWallet");
									}}
								>
									Connect Wallet
								</button>
							)}
							<div className="amount">
								<h5>Select Amount</h5>
								<p>Minimum Amount for mint is 1</p>
								<div className="qt_selector">
									<button
										onClick={() => {
											if (!currentUser) {
												NotificationManager.error("Please Connect Wallet First")
												return
											}
											let mint = currQty - 1;
											if (mint < 1) mint = 1;
											if (mint > maxNFTs) mint = maxNFTs;
											if (Number(mint) > maxNFTs) {
												mint = 0
											}
											setCurrQty(Number(mint));
										}}
									>
										-
									</button>

									<input
										type="text"
										name=""
										required=""
										id=""
										onKeyPress={(e) => {
											if (!/^\d+$/.test(e.key)) e.preventDefault();
										}}
										onChange={(e) => {
											e.preventDefault();
											if (
												Number(e.target.value) + Number(totalMintedByUser) <
												maxNFTs
											)
												setCurrQty(Number(e.target.value));
											else
												setCurrQty(Number(maxNFTs));
										}}
										value={currQty}
									/>

									<button
										onClick={() => {
											if (!currentUser) {
												NotificationManager.error("Please Connect Wallet First")
												return
											}
											let mint = currQty + 1;
											if (mint < 1) mint = 1;
											if (mint > maxNFTs) mint = maxNFTs;
											if (Number(mint) <= maxNFTs) {
												setCurrQty(mint);
											}
										}}
									>
										+
									</button>
								</div>
								<div className="mint_btn multiminting mt-4">
									<button
										className=""
										type="button"
										onClick={async (e) => {
											const wCheck = await WalletConditions(await getCurrentProvider(web3Provider));
											console.log("hereee", wCheck)
											if (wCheck !== undefined && wCheck !== "") {
												handleShowAlert(wCheck);
												return;
											}
											await mintTokens();
										}}
										disabled={
											!currentUser || currQty <= 0 || !status
										}
									>
										Buy With Crypto
									</button>
								</div>
								<div className="mint_btn multiminting mt-4">
									<button
										className=""
										type="button"
										disabled={
											!currentUser || currQty <= 0 || !status
										}
										onClick={async (e) => {
											setLoading(true);
											let isWhitelisted = await whitelistLogic()
											if (isWhitelisted) { setIsShowModal(true); }
											if (!isWhitelisted) {
												NotificationManager.error(
													"User is not whitelisted",
													"",
													NOTIFICATION_DELAY
												);
												setLoading(false);
												return;
											}
											setLoading(false);
										}}
									>
										Buy With Credit Card
									</button>
								</div>

							</div>
						</div>
					</div>
					{isShowModal ? (
						<>
							{orderCompleted ? (
								<>
									<div className="popup-container">
										<div className="popup_outer">
											<div className="box">
												<div class="pop_up">
													<div class="pop_up_inner">
														<div class="pop_up_upper"></div>
														<div class="pop_up_content">
															<h3>Transaction Completed Successfully.</h3>
															<h3>View NFT in your Profile</h3>
															<div class="pop_up_footer_note">
																<p>NFT Delivery Time</p>
																<p>Credit Card Purchase: Up to 12 Hrs</p>
																<p>Crypto Purchase: Up to 30 Mins</p>
																<button class="pop_up_close_btn" onClick={() => {
																	setIsShowModal(false);
																	setCurrQty(1)
																	setUserData({
																		fullName: "",
																		emailAddress: "",
																		country: "",
																		state: "",
																		postalCode: "",
																	})
																	setStateList([]);
																	setOrderCompleted(0)
																	setReload(!reload);
																}}>Close</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</>
							) : (
								<>
									<PopupModalStripe
										content={
											<div className="modal-dialog modal-confirm MintingPage">
												<div className="modal-content">
													<div className="modal-body">
														<div className="customborder customText">
															<div className="row">
																<div className="col-md-12 mb-2">
																	<div class="alert warning da-warning">
																		<span className="text-muted">
																			NFT will be minted to
																			<br />
																			<strong>{currentUser}</strong>
																		</span>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-12">
																	<div className="text_content">
																		<h2>Order Summary</h2>
																	</div>
																	<div className="item_section">
																		<table className="table table-bordered">
																			<thead>
																				<tr>
																					<th>Price</th>
																					<th>Qty</th>
																					<th>Total</th>
																				</tr>
																			</thead>
																			<tbody>
																				<tr>
																					<td>${parseFloat(NFT_PRICE_IN_DOLLAR)}</td>
																					<td>{parseInt(currQty)}</td>
																					<td> ${parseFloat(NFT_PRICE_IN_DOLLAR) * parseInt(currQty)} </td>
																				</tr>
																			</tbody>
																		</table>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-12">
																	<div className="form-group">
																		<label for="email">Email</label>
																		<input
																			type="email"
																			autoComplete="off"
																			className="form-control"
																			placeholder="Email Address"
																			value={userData.emailAddress}
																			onChange={(e) => {
																				setUserData({
																					...userData,
																					emailAddress: e.target.value,
																				});
																			}}
																		/>
																	</div>
																</div>
															</div>

															<div className="row">
																<div className="col-6 col-sm-6">
																	<div className="form-group">
																		<label for="country">Select Country</label>
																		<select
																			value={userData.country}
																			className="form-control"
																			onChange={(e) => {
																				updateState(e.target.value);
																				setUserData({
																					...userData,
																					country: e.target.value,
																				});
																			}}
																		>
																			{countryList.map((option) => (
																				<option value={option.sortname}>
																					{option.name}
																				</option>
																			))}
																		</select>
																	</div>
																</div>

																<div className="col-6 col-sm-6">
																	<div className="form-group">
																		<label for="state">Select Area</label>
																		<select
																			value={userData.state}
																			className="form-control"
																			onChange={(e) => {
																				setUserData({
																					...userData,
																					state: e.target.value,
																				});
																			}}
																		>
																			{stateList.length > 0 ? (
																				<>
																					<option>Select Area</option>
																					{stateList.map((option) => (
																						<option value={option.sortname}>
																							{option.name}
																						</option>
																					))}
																				</>
																			) : (
																				<>
																					<option>Select Country First</option>
																				</>
																			)}
																		</select>
																	</div>
																</div>

																<div className="col-md-12">
																	<div className="form-group">
																		<label for="postalcode">Postal Code</label>
																		<input
																			type="text"
																			autoComplete="off"
																			className="form-control"
																			placeholder="Postal Code"
																			value={userData.postalCode}
																			onChange={(e) => {
																				setUserData({
																					...userData,
																					postalCode: e.target.value,
																				});
																			}}
																		/>
																	</div>
																</div>
															</div>

															<div className="row">
																<div className="col-md-12">
																	<div className="form-group">
																		<label for="name">Name on Card</label>
																		<input
																			type="text"
																			autoComplete="off"
																			className="form-control"
																			placeholder="Name on Card"
																			value={userData.fullName}
																			onChange={(e) => {
																				setUserData({
																					...userData,
																					fullName: e.target.value,
																				});
																			}}
																		/>
																	</div>
																</div>
															</div>

															<div className="row">
																<div className="col-md-12 mb-4">
																	<Elements stripe={stripePromise}>
																		<CheckoutForm
																			paymentData={
																				({
																					userData: userData,
																					symbol: SYMBOL,
																					price: NFT_PRICE_IN_DOLLAR,
																					currency: CURRENCY,
																					quantity: currQty,
																					walletAddress: currentUser
																						? currentUser
																						: cookies.selected_account,
																					contractAddress: contractAddress,
																				})
																			}
																		/>
																	</Elements>
																</div>
															</div>


														</div>
													</div>
												</div>
											</div>
										}
										handleClose={() => {
											setIsShowModal(false);
											setOrderCompleted(0)
											setUserData({
												fullName: "",
												emailAddress: "",
												country: "",
												state: "",
												postalCode: "",
											})
											setStateList([]);
											setCurrQty(1)
											setReload(!reload);
										}}
									/>
								</>
							)}
						</>
					) : (
						<></>
					)}
				</div>
			</section >
			<Footer />
		</div >
	);
}

export default Barrett;
