import React from "react";
import bodyBGImg from "./../../assets/images/background.jpg";
import metaMarineBG from "./../../assets/metamarine/banner.png";
import digitalArmsLogo from "./../../assets/metamarine/digitalArmsLogo.png";
import metaMarineImage1 from "./../../assets/metamarine/metaMarine-Image1.png";
import metaMarineImage2 from "./../../assets/metamarine/metaMarine-Image2.png";

import Footer from "../components/footer";

import "./../../assets/metamarine/style.css";

function AboutMetaMarine() {
  var bgImgStyle = {
    backgroundImage: `url(${bodyBGImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundColor: "#000",
  };
  var bannerBG = {
    backgroundImage: `url(${metaMarineBG})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundColor: "#000",
  };

  return (
    <>
      <section className="metamarinesectionabout" style={bgImgStyle}>
        <section
          className="banner-section banner position-relative"
          style={bannerBG}
        >
          <div className="container">
            <div className="banner"></div>
          </div>
        </section>
        <div className="banner-image text-center">
          <img
            src={digitalArmsLogo}
            alt="Digital Arms Logo"
            className="img-fluid"
          />
        </div>

        <section className="Meta-marine-section pb-5">
          <div className="container">
            <div className="row meta-marine-banner text-white">
              <h3 className="text-center text-white marine-Heading mt-3">
                Meta Marines
              </h3>
              <p className="meta-marine-paragraph text-center">
                Welcome to the War of the Meta Marines, a world of adventure and
                rewards set in the fast-paced and exciting FPS metaverse. Where
                rare weapons are hidden and await those brave enough to join the
                front lines. Join the decentralized factions of Meta Marines and
                fight for land and assets as you mine for $HNTR tokens in fierce
                meta-battles.
              </p>
              <p className="meta-marine-paragraph text-center">
                With the token war in full swing, the scarcity of crypto assets
                has reached breaking point. Digital Arms Generals carefully
                watch over their lieutenants, selecting clans for outstanding
                strategy and resilience in battle. Choose your soldier class and
                eliminate your weaknesses as you forge a path to victory and
                reap the rewards.
              </p>
            </div>
            <div className="image-meta d-flex align-item-center justify-content-center">
              <img src={metaMarineImage1} alt="" className="img-fluid" />
            </div>
          </div>
        </section>
        <section className="Meta-marine-section pb-5">
          <div className="container">
            <div className="row meta-marine-banner  text-white">
              <div className="Marine-inner-Header position-relative">
                <h2 className="text-center text-white marine-Heading  mb-4">
                  Digital Firearms and Custom Skins
                </h2>
              </div>

              <p className="meta-marine-paragraph mt-5 text-center">
                Holding a Meta Marine comes with numerous opportunities for
                growth and reward. With 100% APY bonus pools available for NFT
                holders, you have the chance to claim your share of the prize
                pool. Staked $HNTR rewards and future airdrops are just the
                beginning. Gain access to exclusive digital firearms and custom
                skins, and the chance to receive free future soldier drops in
                upcoming collections.
              </p>
            </div>
            <div className="image-meta d-flex align-item-center justify-content-center">
              <img src={metaMarineImage2} alt="" className="img-fluid" />
            </div>
          </div>
          
        </section>

        <section className="Meta-marine-section pb-5">
          <div className="container">
            <div className="row meta-marine-banner text-white ">
              <div className="Marine-inner-Header position-relative">
                <h2 className="text-center text-white marine-Heading mb-4">
                  Meta Marines Avatars
                </h2>
              </div>
              <p className="meta-marine-paragraph mt-5 text-center">
                The Meta Marine avatars are now a playable game character in
                Netvrk's Inaugural Mine Runner game. Mine Runner is the first
                game set in the Netvrk Metaverse. Built in Unreal Engine 5, Mine
                Runner brings a new dimension to mining, giving players the
                ability to earn items, upgrades, and points, as they progress
                through the cavernous mines of Netvrk
              </p>
              <iframe
                className="metamarineiframe"
                src="https://www.youtube.com/embed/Ut2wiWwd7PM"
                title="Netvrk Presents MineRunner: Official Alpha Trailer"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="meta-marine-paragraph mt-4 text-center">
                The value of owning a Meta Marine avatar was in its ownership,
                which provided proof of authenticity and ownership of virtual
                items in the gaming world. These NFT assets were unique and
                could not be duplicated or altered, preserving their rarity and
                value. They were also cross-platform compatible, giving players
                greater flexibility and value as they could be used across
                multiple games and platforms.
              </p>
            </div>
          </div>
        </section>
        <section className="Meta-marine-section pb-5">
          <div className="container">
            <div className="row meta-marine-banner text-white">
              <div className="Marine-inner-Header position-relative">
                <h2 className="text-center text-white marine-Heading mb-4">
                  Prize Pool
                </h2>
              </div>

              <p className="meta-marine-paragraph mt-4 text-white text-center">
                For those that held three avatars, they were in with a chance of
                winning a massive 500,000 HNTR prize, vested over a 12-month
                period.And with bonus staking pools available for NFT holders,
                the opportunities for growth were endless.The mystery of what
                was to come next was palpable, with rumors of partnered NFT
                groups in the works. The future of the Meta Marines was bright,
                and those who joined the battle today would reap the rewards
                tomorrow.
              </p>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
}
export default AboutMetaMarine;
