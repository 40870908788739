import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCategory } from "../../helpers/getterFunctions";
import Firearmsvg from "../SVG/Firearmsvg";
import EmailForm from "./emailOctopus";

const Footer = function () {
	const [catg, setCatg] = useState([]);
	
	useEffect(() => {
		async function setCategory() {
			const cat = await getCategory();
			setCatg(cat);
		}
		setCategory();
	}, []);


	// const subscribe = () => {

	// }

	return (
		<footer className="footer-dark pt-5 pb-5">
			<div className="container nav-container">
				<div className="row">
					<div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-3">
						<div className="widget text-widget">
							<img
								alt=""
								src={"../img/logo.svg"}
								className="mb-3"
							/>
							<p>Unlock the Power of IP-Licensed Digital Collectibles</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-3">
						<div className="menu-widget widget">
							<h5 className="mb-4">Marketplace</h5>
							<ul>
								<li>
									<Link to="/marketplace">All NFTs</Link>
								</li>
								{catg?.length > 0
									? catg?.map((c, key) => {
										return (
											<li key={key}>
												<Link
													to={`/marketplacecollection/${c.name}`}
													className="sub-items">
													{/* <Firearmsvg /> */}
													{c.name}
												</Link>
											</li>
										);
									})
									: ""}
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-3">
						<div className="menu-widget widget">
							<h5 className="mb-4">Company</h5>
							<ul>
								<li>
									<a href={`${process.env.REACT_APP_BLOG_URL}about`}>About</a>
								</li>
								{/* <li><a href={`${process.env.REACT_APP_BLOG_URL}careers`}>Careers</a></li> */}
								<li>
									<a href={`${process.env.REACT_APP_BLOG_URL}contact-us`}>Contact</a>
								</li>
								<li>
									<a href={`${process.env.REACT_APP_BLOG_URL}legal`}>Legal</a>
								</li>
								<li>
									<a href={`${process.env.REACT_APP_BLOG_URL}terms-conditions`}>T&Cs</a>
								</li>
								<li>
									<a href={`${process.env.REACT_APP_BLOG_URL}privacy-policy`}>Privacy</a>
								</li>
								<li>
									<a href={`${process.env.REACT_APP_BLOG_URL}disclaimer`}>Disclaimer</a>
								</li>
							</ul>
						</div>
						{/* <div className="menu-widget widget mt-5">
                            <h5 className='mb-4'>For Linking</h5>
                            <ul>
                                <li><Link to={'/Author'}>author</Link></li>
                                <li><Link to={'/login'}>Login</Link></li>
                                <li><Link to={'/register'}>Register</Link></li>
                                <li><Link to={'/marketplace'}>MarketPlace</Link></li>
                                <li><Link to={"/marketplacecollection"}>Market Place Collection</Link></li>
                                <li><Link to={"/NFTdetails"}>NFTdetails</Link></li>
                                <li><Link to={'/contact'}>Contact</Link></li>
                                <li><Link to={"/userprofile"}>Profile</Link></li>
                                <li><Link to={"/collection"}>Collection</Link></li>
                                <li><Link to={"/brand"}>Collection With Collection</Link></li>
                                <li><Link to={"/collectionActivity"}>Collection Activity</Link></li>
                                <li><Link to={"/blog"}>Blog</Link></li>
                                <li><Link to={"/blogtagged"}>BlogTagged</Link></li>
                                <li><Link to={'/blogdetails'}>Blogdetails</Link></li>
                            </ul>
                        </div> */}
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-3">
						{/* <div className="widget newsletter-widget">
                            <h5 className="mb-4">Newsletter</h5>
                            <p>Don't miss an NFT drop, signup for the latest news </p>
                            <form action="#" className="form-dark mb-1" id="form_subscribe" method="post" name="form_subscribe">
                                <div className="newsletter_box">
                                    <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" />
                                    <button type='button' id="btn-subscribe">
                                        <i className="arrow_right bg-color-secondary"></i>
                                    </button>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                            <div className="botton-text">your email is safe with us. We don’t spam.</div>

                        </div> */}
						<div className="widget newsletter-widget">
							<h5 className="mb-4">Digital Arms Insider</h5>
							<p>Subscribe for early access to NFT drop news!</p>
								<div id="mc_embed_signup">
									<EmailForm />
								<div className="botton-text">Your email's safe with us. We only send the good stuff.</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="subfooter">
				<div className="container nav-container">
					<div className="row align-items-end">
						<div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 ">
							<span onClick={() => window.open("", "_self")}>
								<span className="copy">
									&copy;Copyright 2023 - Digital Arms Group PTY LTD -in partnership with
									BlockchainAustralia
								</span>
							</span>
						</div>
						<div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 "></div>
						<div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 "></div>
						<div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 ">
							<ul className="social-icons">
								<li><a class="" href="https://www.facebook.com/digitalarmsnft" target="_blank" rel="noreferrer"><i class="fa-brands fa-facebook-f fa-lg"></i></a></li>
								<li><a class="" href="https://twitter.com/DigitalArmsNFT" target="_blank" rel="noreferrer"><i class="fa-brands fa-twitter fa-lg"></i></a></li>
								<li><a class="" href="https://www.linkedin.com/company/digital-arms-hntr-token/" target="_blank" rel="noreferrer"><i class="fa-brands fa-linkedin-in fa-lg"></i></a></li>
								<li><a class="" href="https://discord.gg/digital-arms" target="_blank" rel="noreferrer"><i class="fa-brands fa-discord fa-lg"></i></a></li>
								<li><a class="" href="https://t.me/huntertokenofficial" target="_blank" rel="noreferrer"><i class="fa-brands fa-telegram fa-lg"></i></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};
export default Footer;
