import React from "react";

const PopupModalStripe = props => {
  return (
    <div className="popup-container">
      <div className="popup-box custom_pop stripe">
        <div className="box">
          <span className="close-icon" onClick={props.handleClose}>x</span>
          {props.content}
        </div>
      </div>
    </div>
    
  );
};

export default PopupModalStripe;