import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import img3 from "./assets/img3.png";
import img from "./assets/BARRETT Launch-PopUp.png";
import "./popup.css"

export default function HomePopup() {

    const [open, setOpen] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setOpen(false)
        }, [15000])
    }, [])
    return (
        <div className="modal_popUp_container">
            {/* <button onClick={() => setOpen(true)}>click here</button> */}
            <Modal
                open={open}
                center
                onClose={() => {
                    setOpen(false);
                }}
            >
                {/* if don't want dynamic data */}

                <div className="popup-box_claim">
                    <div className="image-section">
                        <img src={img} alt="/" className="img-fluid" />
                        <button onClick={() => {
                            window.location.href = "/multimintingPage/barrett"
                        }}
                            className="btn_get">GET YOURS NOW!</button>
                    </div>
                </div>

                {/* dynamic */}
                {/* <div className="bg-img ">
<div className="heading">
WELCOME TO THE FUTURE OF <span style={{fontWeight:'bold'}}>COLLECTIBLES!</span>
</div>
<div className="heading_2">
  First IP Licensed Firearm M82A1 Barrett NFTs Now Available!
</div>
            <button className="btn_get2">GET YOURS NOW!</button>
</div> */}


                {/*totally static */}

                {/* <div>
                    <img src={img3} alt="/" className="img-fluid" />
                </div> */}
            </Modal>
        </div>
    );
}