import React from 'react';
import { NavLink } from "react-router-dom";

function Sidebar() {
  return (
    <ul>
        <li><h4>SETTINGS</h4></li>
        <li>
            <NavLink to={'/userprofile'} activeclassname="active-link">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V4C21 3.46957 20.7893 2.96086 20.4142 2.58579C20.0391 2.21071 19.5304 2 19 2H5C4.46957 2 3.96086 2.21071 3.58579 2.58579C3.21071 2.96086 3 3.46957 3 4V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H6ZM12 4.999C13.647 4.999 15 6.35 15 7.999C15 9.647 13.647 11 12 11C10.353 11 9 9.647 9 7.999C9 6.35 10.353 4.999 12 4.999ZM6 17.25C6 15.031 8.705 12.75 12 12.75C15.295 12.75 18 15.031 18 17.25V18H6V17.25Z" fill="white"/>
            </svg>
            Profile</NavLink>
        </li>
        {/* <li>
            <NavLink style={{pointerEvents: 'none'}} to={'/Notifications'}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C12.6193 22.0008 13.2235 21.8086 13.7285 21.4502C14.2335 21.0917 14.6143 20.5849 14.818 20H9.182C9.38566 20.5849 9.76648 21.0917 10.2715 21.4502C10.7765 21.8086 11.3807 22.0008 12 22ZM19 14.586V10C19 6.783 16.815 4.073 13.855 3.258C13.562 2.52 12.846 2 12 2C11.154 2 10.438 2.52 10.145 3.258C7.185 4.074 5 6.783 5 10V14.586L3.293 16.293C3.19996 16.3857 3.12617 16.4959 3.07589 16.6172C3.0256 16.7386 2.99981 16.8687 3 17V18C3 18.2652 3.10536 18.5196 3.29289 18.7071C3.48043 18.8946 3.73478 19 4 19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18V17C21.0002 16.8687 20.9744 16.7386 20.9241 16.6172C20.8738 16.4959 20.8 16.3857 20.707 16.293L19 14.586Z" fill="white"/>
            </svg>
                Notifications
            </NavLink>
        </li>
        <li>
            <NavLink style={{pointerEvents: 'none'}} to="/Offers">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.8966 21.968C12.3666 21.97 11.8566 21.758 11.4826 21.382L3.64564 13.547C3.44131 13.3434 3.28363 13.0978 3.18349 12.8272C3.08335 12.5567 3.04314 12.2676 3.06564 11.98L3.56564 5.41401C3.60018 4.93599 3.80617 4.48652 4.1457 4.14827C4.48524 3.81002 4.93549 3.60574 5.41364 3.57301L11.9796 3.07301C12.0316 3.06201 12.0826 3.06201 12.1346 3.06201C12.6646 3.06201 13.1716 3.27201 13.5446 3.64801L21.3826 11.482C21.5684 11.6677 21.7158 11.8883 21.8164 12.131C21.917 12.3737 21.9687 12.6338 21.9687 12.8965C21.9687 13.1592 21.917 13.4194 21.8164 13.6621C21.7158 13.9048 21.5684 14.1253 21.3826 14.311L14.3106 21.382C14.1254 21.5683 13.905 21.716 13.6623 21.8166C13.4196 21.9172 13.1594 21.9687 12.8966 21.968ZM8.65364 6.65401C8.32475 6.65411 8.00096 6.73531 7.71094 6.89042C7.42093 7.04554 7.17364 7.26978 6.99099 7.54329C6.80834 7.8168 6.69596 8.13113 6.6638 8.45845C6.63164 8.78576 6.68069 9.11595 6.80662 9.41978C6.93255 9.72361 7.13146 9.99169 7.38574 10.2003C7.64002 10.4089 7.94181 10.5516 8.26439 10.6157C8.58698 10.6798 8.92039 10.6633 9.2351 10.5678C9.54982 10.4723 9.83611 10.3006 10.0686 10.068L10.0756 10.062L10.0826 10.055L10.0746 10.062C10.3526 9.78158 10.5414 9.42513 10.6171 9.03759C10.6928 8.65006 10.6522 8.24877 10.5002 7.88432C10.3482 7.51986 10.0918 7.20855 9.76318 6.98961C9.43457 6.77066 9.04851 6.65389 8.65364 6.65401Z" fill="white"/>
            </svg>
                Offers
            </NavLink>
        </li>
        <li>
            <NavLink style={{pointerEvents: 'none'}} to="/AccountSupport">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.9954 6.90001C20.9788 6.73196 20.9198 6.57087 20.824 6.43183C20.7281 6.29279 20.5986 6.18034 20.4474 6.10501L12.4474 2.10501C12.3085 2.03549 12.1553 1.9993 11.9999 1.9993C11.8446 1.9993 11.6914 2.03549 11.5524 2.10501L3.55243 6.10501C3.4017 6.18077 3.27254 6.29335 3.17692 6.43233C3.0813 6.5713 3.02231 6.73217 3.00543 6.90001C2.99443 7.00701 2.04443 17.667 11.5944 21.914C11.722 21.9716 11.8604 22.0014 12.0004 22.0014C12.1404 22.0014 12.2788 21.9716 12.4064 21.914C21.9564 17.667 21.0064 7.00801 20.9954 6.90001ZM12.0004 19.897V12H5.51043C5.12215 10.5783 4.93896 9.1085 4.96643 7.63501L12.0004 4.11801V12H18.4604C17.7014 14.74 15.9624 17.979 12.0004 19.897Z" fill="white"/>
            </svg>
                Account Support
            </NavLink>
        </li>
        <li>
            <NavLink style={{pointerEvents: 'none'}} to="/Earnings">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8003 10.9C9.53031 10.31 8.80031 9.7 8.80031 8.75C8.80031 7.66 9.81031 6.9 11.5003 6.9C13.2803 6.9 13.9403 7.75 14.0003 9H16.2103C16.1403 7.28 15.0903 5.7 13.0003 5.19V3H10.0003V5.16C8.06031 5.58 6.50031 6.84 6.50031 8.77C6.50031 11.08 8.41031 12.23 11.2003 12.9C13.7003 13.5 14.2003 14.38 14.2003 15.31C14.2003 16 13.7103 17.1 11.5003 17.1C9.44031 17.1 8.63031 16.18 8.52031 15H6.32031C6.44031 17.19 8.08031 18.42 10.0003 18.83V21H13.0003V18.85C14.9503 18.48 16.5003 17.35 16.5003 15.3C16.5003 12.46 14.0703 11.49 11.8003 10.9Z" fill="white"/>
            </svg>
                Earnings
            </NavLink>
        </li> */}
    </ul>
  )
}

export default Sidebar
