import React from 'react';
import { Link } from 'react-router-dom';
class ItemNotFound extends React.Component {
  render() {
    window.location.href = "/"
    return <div>

    </div>;
  }
}
export default ItemNotFound;