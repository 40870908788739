import { React, useEffect, useState } from "react";
import Footer from "../../components/footer";
import DevTeam from "./../../../assets/images/devTeam.png";
import "../../components-css/App.css";
import { getMintCollections } from "../../../apiServices";
import coverImg from "../../../assets/Minting/zevbanner.jpg";
import bodyBGImg from "./../../../assets/images/background.jpg";

async function lazyImport(addr) {
	let data = await getMintCollections({ address: addr });

	let fileName = data.type;
	if (!fileName) {
		throw new Error("file not found");
	}
	const calles = import(`../../../helpers/Contract-Calls/${fileName}`);
	return calles;
}

var bgImgStyle = {
    backgroundImage: `url(${bodyBGImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    backgroundPositionY: "top",
    backgroundColor: "#000",
};

function Zev(props) {
	const [toggle, setToggle] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div  style={bgImgStyle}>
			<section
				className="collection_banner pdd_8"
				style={{
					backgroundImage: `url(${coverImg})`,
					backgroundSize: "cover",
					backgroundPosition: "top center",
				}}></section>
			<section className="collection_info">
				<div className="container">
					{/* <ul className="collection_status mt-5 mb-5">
						<li>
							<h4>0</h4>
							<p>Items</p>
						</li>
						<li>
							<h4>TBA</h4>
							<p>HNTR</p>
						</li>
						<li>
							<h4>TBA</h4>
							<p>Status</p>
						</li>
					</ul> */}
					<div className="collection_description text-center mt-5">
						{!toggle ? (
							<p>
								Founded in 2007, ZEV has established itself as one of the most innovative and
								respected companies in the industry, known for producing high-quality products that
								offer unmatched performance and reliability. The company specializes in custom-built
								rifles, pistols, and components that cater to the demands of professional shooters,
								military personnel, and gun enthusiasts. ZEV's commitment to innovation and
								excellence is evident in every product they produce.
							</p>
						) : (
							<>
								<p>
									Founded in 2007, ZEV has established itself as one of the most innovative and
									respected companies in the industry, known for producing high-quality products
									that offer unmatched performance and reliability. The company specializes in
									custom-built rifles, pistols, and components that cater to the demands of
									professional shooters, military personnel, and gun enthusiasts. ZEV's commitment
									to innovation and excellence is evident in every product they produce. Whether
									it's a custom-built rifle for military operations, a pistol for competition
									shooting, or a component to upgrade an existing firearm, ZEV will work with its
									clients to design and build a product that delivers superior performance and
									reliability.{" "}
								</p>
								<p>
									The company's products are used by some of the most elite military and law
									enforcement organizations in the world and have been proven in the most
									challenging environments. ZEV's reputation for quality and reliability has made it
									a trusted brand among firearms enthusiasts, competitive shooters, and
									professionals. With a commitment to excellence, a talented team of experts, and a
									focus on customer satisfaction, ZEV Technologies is poised for continued success
									and growth in the firearms industry.
								</p>
								<p>
									In collaboration with our gaming partners Digital Arms is excited to present the
									world's first IP licensed pistol NFT to web3 gaming, which will be a highly sought
									after sidearm digital collectible to compliment your in-game experience or digital
									armory collection!{" "}
								</p>
							</>
						)}

						{
							<span className="top_arrow">
								<img
									alt=""
									src={!toggle ? "../img/bottom_arrow.png" : "../img/top_arrow.png"}
									onClick={() => setToggle((toggle) => !toggle)}
									className="img-showMore less img-fluid"
								/>
							</span>
						}
					</div>
				</div>
			</section>
			<section className="collection_list mb-5 pb-5">
				<div className="container">
					<div className="event_slider">
						<div className="mintevent text-center">
							<h4 className="mintComingSoon">Coming <br></br>Soon</h4>
						</div>
						{/* <div className="mintevent text-center">
							<div className="start_btn stamintFunctionbtn">Upcoming</div>
							<h4>Mint Event</h4>
							<div className="da_img mb-3">
								<img
									src={DevTeam}
									alt=""
								/>
							</div>
							<button
								className="connect_wallet_btn mb-4 disabled"
								disabled>
								Connect Wallet
							</button>
							<div className="mintprice">Mint Price N/A HNTR</div>
							<div className="amount">
								<h5>Select Amount</h5>
								<p>Minimum Amount for mint is 1*</p>
								<div className="qt_selector">
									<button>-</button>

									<input
										type="text"
										name=""
										required=""
										id=""
										value={"0"}
										readOnly
									/>

									<button>+</button>
								</div>
								<div className="mint_btn mt-4">
									<button
										className=""
										type="button"
										disabled>
										Mint
									</button>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
}

export default Zev;
