import { ethers } from "ethers";
import { NotificationManager } from "react-notifications";
import { web3authInstance } from "./../menu/header";
import { NOTIFICATION_DELAY } from "../../helpers/constants";


export const WalletConditions = async (web3Provider) => {
	try {
		console.log("onClickHandler 1111")
		const cookies = document.cookie;
		console.log("In wallet condi", web3Provider)
		let str = cookies;
		console.log("wc1111")
		str = str.split("; ");

		console.log("onClickHandler str", str)
		const result = {};
		for (let i in str) {
			const cur = str[i].split("=");
			result[cur[0]] = cur[1];
		}
		console.log("onClickHandler 2222")

		//  await provider1.initModal();
		//    let provider = new ethers.providers.Web3Provider(web3authProvider.provider);

		// console.log("web3authInstance",provider1.provider)
		if ((result.selected_account === undefined || result.selected_account === "")) {
			console.log("onClickHandler return here");
			return "notConnected";
		} else {
			console.log("onClickHandler provider1 called Here");
			const provider1 = web3Provider
			console.log("onClickHandler provider1 Found", provider1);
			let ethersProvider;
			try {
				console.log("wc2222")
				console.log("onClickHandler ethersProvider Found");
				ethersProvider = new ethers.providers.Web3Provider(web3Provider);
			}
			catch (e) {
				console.log("onClickHandler error in wallet conditions", e)
				return
			}
			const signer = ethersProvider?.getSigner();
			console.log("signer in walletcond", signer)
			const address = await signer?.getAddress();
			console.log("address from this", address)
			const chainId = await signer?.getChainId()
			console.log("Chain id", chainId)
			if (chainId?.toString() !== process.env.REACT_APP_NETWORK_ID?.toString()) {
				console.log("onClickHandler WalletConditions Returns Account");
				return "chainId"
			}
			const balance = ethers.utils?.formatEther(
				await ethersProvider?.getBalance(address) // Balance is in wei
			);
			console.log("wc3333")
			console.log("onClickHandler balance of user is----->", balance, typeof (balance));
			if (balance === "0.0") {
				console.log("onClickHandler WalletConditions Returns false");
				NotificationManager.error(`Insufficient Balance : ${balance}`, "", NOTIFICATION_DELAY)
				return "false"
			}
			if (address?.toLowerCase() !== result?.selected_account?.toLowerCase()) {
				console.log("onClickHandler WalletConditions Returns Account");
				return "account";
			}
			console.log("wc4444")
		}
		console.log("onClickHandler WalletConditions Returns Blank");
		return "";
	}
	catch (err) {
		console.log("In wallect condition err", err)
		return "account"
	}

};
