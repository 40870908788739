import React, { useState, useEffect } from "react";
import Slider from "./slick-loader/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import {
  getNFTs,
  getPrice,
} from "./../../helpers/getterFunctions";
import "./../components-css/App.css";
import { convertToEth } from "../../helpers/numberFormatter";
import { Tokens } from "../../helpers/tokensToSymbol";
import moment from "moment";

function ItemsList() {

  const [putOnSaleItems, setPutOnSaleItems] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const reqData = {
          page: 1,
          limit: 12,
        };
        const res = await getNFTs(reqData);
        for (let i = 0; i < res.length; i++) {
          const orderDet = await getPrice(res[i].orderData);
          const brandDet = res[i].brandData;
          res[i] = {
            ...res[i],
            price: !orderDet?.price?.$numberDecimal
              ? "--"
              : parseFloat(Number(convertToEth(orderDet?.price?.$numberDecimal))
                ?.toFixed(4)
                ?.slice(0, -2)),
            saleType: orderDet?.salesType,
            deadline: orderDet?.deadline,
            brand: brandDet,
            paymentToken: orderDet?.paymentToken,
          };
        }

        setPutOnSaleItems(res);

      } catch (e) {
        console.log("Error in fetching all collections list", e);
      }
    };
    fetch();
  }, []);

  var settings = {
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    ltr: true,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="nft">
      <Slider {...settings}>
        {putOnSaleItems.length > 0
          ? putOnSaleItems?.map((card, key) => {
            return (
              <div className="items_slide h-100" key={key}>
                <div className="items_profileimg">
                  <a href={`/collectionwithcollection/${card.brand?._id}`}>
                    <div className="profile_left nft-logo-img">
                      <img
                        alt=""
                        className="profile_img creatorImg"
                        src={card.brand?.logoImage}
                        onError={(e) =>
                          (e.target.src = "../img/collections/list4.png")
                        }
                      />
                    </div>
                  </a>
                </div>
                <a href={`/NFTdetails/${card.id}`} className="nft-cont">
                  {
                    (card?.previewImg || card?.image) ?
                      <img
                        alt=""
                        src={card?.previewImg ? card?.previewImg : card.image}
                        className="img-fluid items_img my-3"
                        onError={(e) => {
                          e.target.src = card?.previewImg ? card?.previewImg : "../img/collections/list4.png";
                        }}
                      /> : <div className="blur_img_div h-100">
                        <img
                          src={card?.collectionData[0]?.logoImage}
                          className='img-fluid items_img w-100 blur_img'
                          alt='nft'
                          onError={(e) => {
                            e.target.src = "../img/collections/list4.png";
                          }}
                        />
                        <span className="no_img">No Image</span>
                      </div>
                  }
                </a>
                <div className="items_text nft-info-div">
                  <div className="items_info">
                    <div className="items_left ">
                      <h3 data-toggle="tooltip" title={card?.name}>
                        {card?.name?.length > 50
                          ? card?.name?.slice(0, 50) + "..."
                          : card?.name}
                      </h3>
                      <p>
                        {card.price}{" "}
                        {
                          Tokens[card?.paymentToken?.toLowerCase()]
                            ?.symbolName
                        }
                      </p>
                    </div>

                  </div>
                  <Link
                    to={`/NFTdetails/${card.id}`}
                    className="border_btn  title_color w-100 buy-btn"
                  >
                    {card.saleType === 3
                      ? "Buy Now"
                      : ((card.saleType === 4) && moment.utc(card?.deadline * 1000).local().format() >= moment(new Date()).format())
                        ? "Place Bid" :
                        moment.utc(card?.deadline * 1000).local().format() < moment(new Date()).format() ? "Auction Ended"
                          : "View"}
                  </Link>
                </div>
              </div>
            );
          })
          : ""}
        {putOnSaleItems?.length >= 5 ? (
          <div className="items_slide last_slide">
            <Link
              to={"/marketplace"}
              className="view_slide align-items-center justify-content-center d-flex"
            >
              View All
            </Link>
          </div>
        ) : (
          ""
        )}
      </Slider>
      {putOnSaleItems?.length >= 12 ? (<div className="col-md-12 text-center mt-5">
        <Link to={"/marketplace"} className="view_all_bdr">
          View All
        </Link>
      </div>) : ""}
    </div>
  );
}

export default ItemsList;
