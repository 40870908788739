import React from 'react'

function Twogrid() {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 2.46429C0 1.81072 0.259629 1.18392 0.721773 0.721773C1.18392 0.259629 1.81072 0 2.46429 0H7.39286C8.04643 0 8.67323 0.259629 9.13537 0.721773C9.59751 1.18392 9.85714 1.81072 9.85714 2.46429V7.39286C9.85714 8.04643 9.59751 8.67323 9.13537 9.13537C8.67323 9.59751 8.04643 9.85714 7.39286 9.85714H2.46429C1.81072 9.85714 1.18392 9.59751 0.721773 9.13537C0.259629 8.67323 0 8.04643 0 7.39286V2.46429ZM13.1429 2.46429C13.1429 1.81072 13.4025 1.18392 13.8646 0.721773C14.3268 0.259629 14.9536 0 15.6071 0H20.5357C21.1893 0 21.8161 0.259629 22.2782 0.721773C22.7404 1.18392 23 1.81072 23 2.46429V7.39286C23 8.04643 22.7404 8.67323 22.2782 9.13537C21.8161 9.59751 21.1893 9.85714 20.5357 9.85714H15.6071C14.9536 9.85714 14.3268 9.59751 13.8646 9.13537C13.4025 8.67323 13.1429 8.04643 13.1429 7.39286V2.46429ZM0 15.6071C0 14.9536 0.259629 14.3268 0.721773 13.8646C1.18392 13.4025 1.81072 13.1429 2.46429 13.1429H7.39286C8.04643 13.1429 8.67323 13.4025 9.13537 13.8646C9.59751 14.3268 9.85714 14.9536 9.85714 15.6071V20.5357C9.85714 21.1893 9.59751 21.8161 9.13537 22.2782C8.67323 22.7404 8.04643 23 7.39286 23H2.46429C1.81072 23 1.18392 22.7404 0.721773 22.2782C0.259629 21.8161 0 21.1893 0 20.5357V15.6071ZM13.1429 15.6071C13.1429 14.9536 13.4025 14.3268 13.8646 13.8646C14.3268 13.4025 14.9536 13.1429 15.6071 13.1429H20.5357C21.1893 13.1429 21.8161 13.4025 22.2782 13.8646C22.7404 14.3268 23 14.9536 23 15.6071V20.5357C23 21.1893 22.7404 21.8161 22.2782 22.2782C21.8161 22.7404 21.1893 23 20.5357 23H15.6071C14.9536 23 14.3268 22.7404 13.8646 22.2782C13.4025 21.8161 13.1429 21.1893 13.1429 20.5357V15.6071Z" fill=""/>
    </svg>
  )
}

export default Twogrid
