/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Wallet from "../SVG/wallet";
import { convertToEth } from "../../helpers/numberFormatter";
import "../components-css/App.css";
import evt from "../../events/events";
import BigNumber from "bignumber.js";
import { useCookies } from "react-cookie";
import DevTeam from "./../../assets/images/devTeam.png";
import { NOTIFICATION_DELAY } from "../../helpers/constants"
import Spinner from "../components/Spinner"
import { WalletConditions } from "../components/WalletConditions"
import PopupModal from "../components/AccountModal/popupModal";
import ProgressModal from "../components/AccountModal/ProgressModal";
import { getAllCountries, getUserData, getUserQuantity, createPaymentRequest } from '../../apiServices';
import { NotificationManager } from "react-notifications";


evt.setMaxListeners(1);
function MintEventSlider(props) {
  let contract = props.calls;
  const contractAddress = props.id;
  const [cookies] = useCookies([]);
  const [currQty, setCurrQty] = useState(0);
  const [price, setPrice] = useState();
  const [maxNFT, setMaxNFT] = useState(0);
  const [currentUser, setCurrentUser] = useState()
  const [reload, setReload] = useState(false)
  const [status, setStatus] = useState("")
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    fullName: "",
    emailAddress: "",
    country: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [createPayment, setCreatePayment] = useState("");
  const [closeDisabled, setCloseDisabled] = useState(true);

  useEffect(() => {
    setCurrentUser(cookies.selected_account)
  }, [cookies.selected_account])

  const fetchData = async () => {
    setLoading(true)
    let { fetchInfo, fetchUserBal, maxTokenPerWallet } = await contract;
    let maxtoken = 0;
    try {
      maxtoken = await maxTokenPerWallet(props.id)
      maxtoken = maxtoken.toNumber();
    } catch (err) {
      console.log("Error in maxTokenPerWallet")
    }
    let getcateg = 0;
    try {
      getcateg = await fetchInfo(props.id)
    } catch (err) {
      console.log("Error in fetchInfo")
    }
    if (currentUser) {
      console.log("maxtoken called", maxtoken)
      let qtyRequest = {
        "walletAddress": currentUser,
        "contractAddress": contractAddress
      };
      const userQtyDB = await getUserQuantity(qtyRequest);

      let bal = await fetchUserBal(currentUser, props.id)
      console.log("bal ", bal.toNumber(), "maxtoken ", maxtoken)
      let QtyBal = parseInt(bal) + parseInt(userQtyDB);
      if (parseInt(getcateg[3]) > QtyBal) {
        if (maxtoken > parseInt(getcateg[3])) {
          setMaxNFT(parseInt(getcateg[3]) - QtyBal);
        }
        else {
          setMaxNFT(maxtoken - QtyBal);
        }
      } else {
        setMaxNFT(0);
      }
      try {
        const userInfo = await getUserData();
        setUserData(userInfo);
      }
      catch (err) {
        console.log("Error in getting userInfo", err)
      }
      try {
        const countries = await getAllCountries();
        setCountryList(countries);
      } catch (err) {
        console.log("Error in getting countryList", err)
      }

    }
    setStatus(getcateg[4])
    setPrice(convertToEth(new BigNumber(getcateg[0]?.toString())));
    setLoading(false)
  };

  useEffect(() => {
  }, [createPayment])
  useEffect(() => {
    if (props.id)
      fetchData();
    setCurrQty(0)
  }, [props.id, currentUser, reload]);

  const connectWalletEvent = () => {
    evt.emit("connectWallet");
  };

  const mintFunction = async (qty, price, user) => {
    let { testMint } = await contract;
    let result = await testMint(props.id, qty, price, user);
    return result
  };

  const confirmBuyWithNFTCC = (user) => {
    setIsShowModal(true);
    setLoading(false)
  };

  const dataLoader = [{
    desc: "Creating payment link. Please Wait... ",
    event: createPayment
  }]

  const handleBuyWithNFTCC = async () => {
    setCreatePayment("initiated");
    // setIsShowModal(false);


    if (userData.fullName === undefined || userData.fullName === "") {
      NotificationManager.error("Name is Empty", "", NOTIFICATION_DELAY);
      return;
    }
    if (userData.emailAddress === undefined || userData.emailAddress === "") {
      NotificationManager.error("Email Address is Empty", "", NOTIFICATION_DELAY);
      return;
    }
    if (userData.country === undefined || userData.country === "") {
      NotificationManager.error("Country is Empty", "", NOTIFICATION_DELAY);
      return;
    }
    if (currQty === 0) {
      NotificationManager.error("Not enough quantity", "", NOTIFICATION_DELAY);
      return;
    }
    setIsShowModal(false);
    setIsShowPopup(true);
    try {
      let amount = parseInt(currQty) * parseInt(process.env.REACT_APP_ITEM_PRICE);
      let paymentRequest = {
        "walletAddress": currentUser,
        "contractAddress": contractAddress,
        "amount": amount,
        "quantity": parseInt(currQty),
        "currency": process.env.REACT_APP_ITEM_CURRENCY,
        "fullName": userData.fullName,
        "emailAddress": userData.emailAddress,
        "country": userData.country
      };
      const createPaymentLink = await createPaymentRequest(paymentRequest);
      console.log("createPaymentLink", createPaymentLink)
      let paymentLink = createPaymentLink?._links?.redirect?.href;
      if (paymentLink !== undefined) {
        setCreatePayment("success");
        setCloseDisabled(false);
        window.open(paymentLink, 'sharer', 'toolbar=0,status=0,width=500,height=600');
      } else {
        setCreatePayment("fail")
        setCloseDisabled(false);
        NotificationManager.error("Error in Payment Request", "", NOTIFICATION_DELAY);
        return;
      }

    } catch (errorAuthToken) {

      console.log("Error in  AuthToken", errorAuthToken)
    }
  }

  const handleClosePopup = async () => {
    setIsShowModal(false);
    setReload(!reload)
  }
  const closePopup = () => {
    setIsShowPopup(false);
    setCreatePayment("");
    setReload(!reload)
  }

  return (

    <div className="col-md-12">
      {loading ? <Spinner /> : ""}
      {isShowPopup ?
        <ProgressModal datas={dataLoader}
          onRequestClose={() => {
            closePopup();
            setIsShowPopup(!isShowPopup)
          }}
          disabled={closeDisabled} /> : ""}
      <div className="mintevent text-center">

        <div className="start_btn stamintFunctionbtn">
          Start
          <span>{status ? status : ""}</span>
        </div>
        <h4>Mint Event</h4>
        <div className="da_img mb-3">
          <img src={DevTeam} alt="" />
        </div>
        {!currentUser ? (
          <button
            className="connect_wallet_btn mb-4"
            onClick={() => {
              connectWalletEvent();
            }}
          >
            {" "}
            <Wallet /> Connect Wallet
          </button>
        ) : (
          ""
        )}
        <div className="mintprice">Mint Price {price} HNTR</div>
        <div className="amount">
          <h5>Select Amount</h5>
          <p>Minimum Amount for mint is 1*</p>
          <div className="qt_selector">
            <button
              onClick={() => {
                let mint = currQty - 1;
                if (mint < 1) mint = 0;
                if (mint > maxNFT) mint = maxNFT;
                setCurrQty(Number(mint));
              }}
            >
              -
            </button>

            <input
              type="text"
              name=""
              required=""
              id=""
              onKeyPress={(e) => {
                if (!/^\d+$/.test(e.key)) e.preventDefault();
              }}
              onChange={(e) => {
                if (Number(e.target.value) > maxNFT)
                  e.preventDefault();
                if (Number(e.target.value) > maxNFT) setCurrQty(Number(maxNFT));
                else
                  setCurrQty(Number(e.target.value));
              }}

              value={currQty}
            />

            <button
              onClick={() => {
                let mint = currQty + 1;
                if (mint < 1) mint = 0;
                if (mint > maxNFT) mint = maxNFT;
                setCurrQty(Number(mint));
              }}
            >
              +
            </button>
          </div>
          <div className="mint_btn multiminting mt-4">
            <button
              className=""
              type="button"
              onClick={async (e) => {
                const wCheck = await WalletConditions();
                if (wCheck !== undefined && wCheck !== "") {
                  props.handleShowAlert(wCheck);
                  return;
                }
                await mintFunction(currQty, price, currentUser);
                setReload(!reload)
              }}
              disabled={!currentUser || currQty <= 0 || status !== "Live"}
            >
              Buy With Crypto
            </button>
          </div>

          <div className="mint_btn multiminting mt-4">
            <button
              className=""
              type="button"
              onClick={async (e) => {
                setLoading(true);
                const wCheck = await WalletConditions();
                if (wCheck !== undefined && wCheck !== "") {
                  props.handleShowAlert(wCheck);
                  return;
                }

                confirmBuyWithNFTCC(currentUser);
              }}
              disabled={!currentUser || currQty <= 0 || status !== "Live"}
            >
              Buy With Credit Card
            </button>
          </div>
        </div>
      </div>

      {isShowModal ? <>
        <PopupModal content={
          <div className="modal-dialog modal-confirm MintingPage">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row customborder customText">
                  <div className="col-md-12 text-center mb-2">
                    <span className="text-muted">
                      NFT will be minted to<br />
                      <strong>{currentUser}</strong>
                    </span>
                  </div>
                  <div className="col-md-12 mb-4">
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      placeholder="Full Name"
                      value={userData.fullName}
                      onChange={(e) => {
                        setUserData({ ...userData, fullName: e.target.value })
                      }}
                    />
                  </div>
                  <div className="col-md-12 mb-4">
                    <input
                      type="email"
                      autoComplete="off"
                      className="form-control"
                      placeholder="Email Address"
                      value={userData.emailAddress}
                      onChange={(e) => {
                        setUserData({ ...userData, emailAddress: e.target.value })
                      }}
                    />
                  </div>
                  <div className="col-md-12 mb-4">
                    <select
                      value={userData.country}
                      className="form-control"
                      onChange={(e) => {
                        setUserData({ ...userData, country: e.target.value })
                      }}
                    >
                      {countryList.map((option) => (
                        <option value={option.sortname}>{option.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-success" onClick={handleBuyWithNFTCC}>Proceed</button>
                <button type="button" className="btn btn-danger" onClick={handleClosePopup}>Cancel</button>
              </div>
            </div>
          </div>
        } handleClose={handleClosePopup} />

      </> : <></>}

    </div>

  );
}

export default MintEventSlider;
