import React from 'react'

function Firearmsvg() {
  return (
    <svg
        width='23'
        height='25'
        viewBox='0 0 23 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M22.4344 0.214526C22.1483 -0.0715088 21.6846 -0.0715088 21.3986 0.214526L20.4191 1.19397L19.9012 0.67605C19.6152 0.390014 19.1514 0.390014 18.8654 0.67605C18.5794 0.962085 18.5794 1.42581 18.8654 1.71184L19.3833 2.22976L12.9951 8.61799L11.9593 7.5822L10.9235 8.61799L11.9593 9.65378L8.85189 12.7612L7.8161 11.7254L6.78031 12.7612L7.8161 13.797L5.74452 15.8686C5.64228 15.9708 5.5726 16.101 5.54423 16.2428L5.29472 17.4904L4.04716 17.7399C3.90536 17.7683 3.77514 17.838 3.67289 17.9402L1.60131 20.0118C0.935004 20.6781 0.934955 21.7623 1.60131 22.4286L3.67289 24.5002C4.33925 25.1666 5.42343 25.1665 6.08979 24.5002L9.88773 20.7023C9.98998 20.6 10.0597 20.4698 10.088 20.328L10.5905 17.8156L12.495 18.7678C12.7769 18.9088 13.1175 18.8535 13.3404 18.6306L15.412 16.5591C15.698 16.273 15.698 15.8093 15.412 15.5233L13.8583 13.9696L14.6729 13.155C15.3092 12.5186 15.5838 11.6118 15.4073 10.7293L14.9986 8.68601L22.4344 1.25037C22.7204 0.964331 22.7204 0.500561 22.4344 0.214526ZM13.8583 16.0412L12.6779 17.2216L11.2968 16.5311L12.8225 15.0054L13.8583 16.0412Z'
            fill='#EF981D'
        />
        <path
            d='M3.67297 14.8327C3.95901 14.5467 3.95901 14.083 3.67297 13.797L4.70876 12.7612L2.63713 10.6896L1.60139 11.7254C1.31536 11.4393 0.851636 11.4393 0.5656 11.7254C0.279565 12.0114 0.279565 12.4751 0.5656 12.7612L2.63718 14.8327C2.92322 15.1188 3.38694 15.1188 3.67297 14.8327Z'
            fill='#EF981D'
        />
        <path
            d='M13.1677 4.30222C13.4538 4.01619 13.4538 3.55247 13.1677 3.26643L12.1319 2.23064C11.8459 1.9446 11.3822 1.9446 11.0961 2.23064L3.67285 9.65388L5.74444 11.7255L13.1677 4.30222Z'
            fill='#EF981D'
        />
    </svg>
  )
}

export default Firearmsvg
