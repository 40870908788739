import { React, useEffect, useState } from "react";
import Footer from "../../components/footer";
import DevTeam from "./../../../assets/images/devTeam.png";
import "../../components-css/App.css";
import { getMintCollections } from "../../../apiServices";
import coverImg from "../../../assets/Minting/cmmg.jpg";
import bodyBGImg from "./../../../assets/images/background.jpg";

async function lazyImport(addr) {
	let data = await getMintCollections({ address: addr });

	let fileName = data.type;
	if (!fileName) {
		throw new Error("file not found");
	}
	const calles = import(`../../../helpers/Contract-Calls/${fileName}`);
	return calles;
}

var bgImgStyle = {
    backgroundImage: `url(${bodyBGImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundColor: "#000",
};

function Cmmg(props) {
	const [toggle, setToggle] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div  style={bgImgStyle}>
			<section
				className="collection_banner pdd_8"
				style={{
					backgroundImage: `url(${coverImg})`,
					backgroundSize: "cover",
					backgroundPosition: "top",
				}}></section>
			<section className="collection_info">
				<div className="container">
					{/* <ul className="collection_status mt-5 mb-5">
						<li>
							<h4>0</h4>
							<p>Items</p>
						</li>
						<li>
							<h4>TBA</h4>
							<p>HNTR</p>
						</li>
						<li>
							<h4>TBA</h4>
							<p>Status</p>
						</li>
					</ul> */}
					<div className="collection_description text-center mt-5">
						{!toggle ? (
							<p>
								CMMG, a leading manufacturer of firearms and related products, was founded in 2002,
								the company has quickly established itself as one of the most innovative and
								reliable providers of high-quality firearms and accessories. With a commitment to
								quality, CMMG has become a trusted brand for law enforcement, military, and civilian
								customers alike. CMMG's firearms are made using state-of-the-art manufacturing
								techniques and the highest quality materials.
							</p>
						) : (
							<>
								<p>
									CMMG, a leading manufacturer of firearms and related products, was founded in
									2002, the company has quickly established itself as one of the most innovative and
									reliable providers of high-quality firearms and accessories. With a commitment to
									quality, CMMG has become a trusted brand for law enforcement, military, and
									civilian customers alike. CMMG's firearms are made using state-of-the-art
									manufacturing techniques and the highest quality materials. The company's products
									are designed with the user in mind, and every firearm is put through rigorous
									testing to ensure that it meets the most stringent standards for performance and
									reliability. CMMG is known for its attention to detail and its commitment to using
									only the best components, and its firearms are trusted by customers worldwide. The
									company's product line includes rifles, pistols, and parts and accessories.
									Whether you are looking for a reliable and accurate firearm for personal defense
									or a rugged and dependable weapon for law enforcement or military use, CMMG has a
									product to meet your needs.
								</p>
								<p>
									The CMMG Mk47™ is already one of our most popular firearms amongst U.S. gun
									enthusiasts and at the same time it has a notable reputation in pop culture and in
									the gaming. CMMG can now reach a worldwide audience by offering licensed NFT’s
									that’ll have the potential to be interoperable throughout the metaverse. Imagine
									purchasing an NFT firearm not just as an investment or a collector’s item, but as
									a tool your avatar can use throughout blockchain gaming platforms. With our NFT’s,
									instead of just using a CMMG Mk47™ in one of the current online games, you’ll be
									able to own a CMMG Mk47™ to which you can trade, sell, or upgrade, in the digital
									marketplace
								</p>
							</>
						)}

						{
							<span className="top_arrow">
								<img
									alt=""
									src={!toggle ? "../img/bottom_arrow.png" : "../img/top_arrow.png"}
									onClick={() => setToggle((toggle) => !toggle)}
									className="img-showMore less img-fluid"
								/>
							</span>
						}
					</div>
				</div>
			</section>
			<section className="collection_list mb-5 pb-5">
				<div className="container">
					<div className="event_slider">
						<div className="mintevent text-center">
							<h4 className="mintComingSoon">Coming <br></br>Soon</h4>
						</div>
						{/* <div className="mintevent text-center">
							<div className="start_btn stamintFunctionbtn">Upcoming</div>
							<h4>Mint Event</h4>
							<div className="da_img mb-3">
								<img
									src={DevTeam}
									alt=""
								/>
							</div>
							<button
								className="connect_wallet_btn mb-4 disabled"
								disabled>
								Connect Wallet
							</button>
							<div className="mintprice">Mint Price N/A HNTR</div>
							<div className="amount">
								<h5>Select Amount</h5>
								<p>Minimum Amount for mint is 1*</p>
								<div className="qt_selector">
									<button>-</button>

									<input
										type="text"
										name=""
										required=""
										id=""
										value={"0"}
										readOnly
									/>

									<button>+</button>
								</div>
								<div className="mint_btn mt-4">
									<button
										className=""
										type="button"
										disabled>
										Mint
									</button>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
}

export default Cmmg;
