import React, { useEffect, useState } from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import "../components-css/sliderMain.css";
import { Link } from "react-router-dom";
import PopupModal from "./AccountModal/popupModal";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const SliderMain = () => {
  const [showAlert, setShowAlert] = useState(false)


  return (
    <div className="container banner-container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="spacer-single"></div>
          {showAlert &&

            <div div className="popup-box custom_pop">
              <div class="cust_modal-content">
                <span class="close-button" onClick={() => { setShowAlert(!showAlert) }}>X</span>
                <div style={{ "color": "#fcb900", "fontSize": "2em", "lineHeight": "3em" }}>
                  BUY HUNTER TOKEN
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <a href="https://www.bitmart.com/trade/en?symbol=HNTR_USDT&amp;layout=basic" target="_blank" onClick={() => { }}>
                    <img src="https://www.hunter-token.com/wp-content/uploads/2022/08/bitmart-logo.png" alt="Bitmart-logo" width="200px" style={{ "padding": "1em 1em" }} />
                  </a>
                  <a href="https://pancakeswap.finance/swap?inputCurrency=0x83451A4E3585FdA74FEb348Ad929f2C4cA189660&outputCurrency=0x55d398326f99059fF775485246999027B3197955&chainId=56&chain=bsc" target="_blank">
                    <img src="https://www.hunter-token.com/wp-content/uploads/2022/10/Brands-Pancake-Swap.png" alt="Brands-Pancake-Swap" width="200px" style={{ "padding": "1em 1em" }} />
                  </a>
                </div>
              </div>
            </div>
          }
          {/* />} */}
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={0}
            duration={600}
            triggerOnce
          >
            {/* <h6 className="hunter-market">HNTR TOKEN</h6> */}
          </Reveal>
          <div className="spacer-10"></div>
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={300}
            duration={600}
            triggerOnce
          >
            <h1 className="main-title title_color text-uppercase">
              DIGItal ARMS
            </h1>
          </Reveal>
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={600}
            duration={600}
            triggerOnce
          >
            <p className="lead description_color mt-3 mb-5">
              Get your hands on the most sought-after and exclusive digital firearms for interoperable gaming with Digital Arms - the #1 NFT firearms supplier!
            </p>
          </Reveal>
          <div className="spacer-10"></div>
          <div className="d-flex">
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={800}
              duration={900}
              triggerOnce
            >
              <Link className="btn-banner" to={"/marketplace"}>
                Explore
              </Link>

              <div className="mb-sm-30"></div>
            </Reveal>

            <Reveal
              className="onStep buyNow"
              keyframes={fadeInUp}
              delay={800}
              duration={900}
              triggerOnce
            >
              <Link className="btn-banner" to="/" onClick={() => {
                setShowAlert(true)
              }}>
                Buy HNTR
              </Link>

              <div className="mb-sm-30"></div>
            </Reveal>
          </div>

        </div>
        <div className="col-lg-6 xs-hide mt-5 mt-lg-0">
          <Reveal
            className="onStep"
            keyframes={fadeIn}
            delay={900}
            duration={1500}
            triggerOnce
          >
            <img src="./img/misc/newHome.png" className="lazy img-fluid" alt="" />
          </Reveal>
        </div>
      </div>
    </div >
  );
}
export default SliderMain;
