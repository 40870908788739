import React from 'react'

function ItemSVG() {
  return (
    <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2.34375C0 1.72215 0.24693 1.12601 0.686468 0.686468C1.12601 0.24693 1.72215 0 2.34375 0L22.6562 0C23.2779 0 23.874 0.24693 24.3135 0.686468C24.7531 1.12601 25 1.72215 25 2.34375V22.6562C25 23.2779 24.7531 23.874 24.3135 24.3135C23.874 24.7531 23.2779 25 22.6562 25H2.34375C1.72215 25 1.12601 24.7531 0.686468 24.3135C0.24693 23.874 0 23.2779 0 22.6562L0 2.34375ZM2.34375 1.5625C2.13655 1.5625 1.93784 1.64481 1.79132 1.79132C1.64481 1.93784 1.5625 2.13655 1.5625 2.34375V7.8125H7.8125V1.5625H2.34375ZM7.8125 9.375H1.5625V15.625H7.8125V9.375ZM9.375 15.625H15.625V9.375H9.375V15.625ZM7.8125 17.1875H1.5625V22.6562C1.5625 22.8635 1.64481 23.0622 1.79132 23.2087C1.93784 23.3552 2.13655 23.4375 2.34375 23.4375H7.8125V17.1875ZM9.375 17.1875V23.4375H15.625V17.1875H9.375ZM17.1875 17.1875V23.4375H22.6562C22.8635 23.4375 23.0622 23.3552 23.2087 23.2087C23.3552 23.0622 23.4375 22.8635 23.4375 22.6562V17.1875H17.1875ZM17.1875 15.625H23.4375V9.375H17.1875V15.625ZM17.1875 7.8125H23.4375V2.34375C23.4375 2.13655 23.3552 1.93784 23.2087 1.79132C23.0622 1.64481 22.8635 1.5625 22.6562 1.5625H17.1875V7.8125ZM15.625 7.8125V1.5625H9.375V7.8125H15.625Z" fill="#EF981D"/>
  </svg>
  )
}

export default ItemSVG
