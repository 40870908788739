import React from "react";
import { createRoot } from 'react-dom/client';
import "./assets/animated.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/elegant-icons/style.css";
import "../node_modules/et-line/style.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import App from "./components/app";
import * as serviceWorker from "./serviceWorker";
import "semantic-ui-css/semantic.min.css";
import { CookiesProvider } from "react-cookie";
import { NotificationContainer } from "react-notifications";




const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
// console.log = () => { }
// console.error = () => { }
// console.debug = () => { }

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <NotificationContainer />
      <App />
    </CookiesProvider>
  </React.StrictMode>,


);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
