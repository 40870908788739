import { React, useState } from "react";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import NotificationManager from "react-notifications/lib/NotificationManager";
import { NOTIFICATION_DELAY } from "../../../helpers/constants";
import { createPayment, updatePayment } from '../../../apiServices';
import eventEmitter from "../../../events/events";

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  console.log("stripeCountry", props.paymentData.userData.country)

  const cardOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#000000',
        '::placeholder': {
          color: '#000000',
        },
      },
      invalid: {
        color: '#ff0000',
      },
    },
    hidePostalCode: true,
    autoComplete: 'off',
    iconStyle: 'solid',
    disabledCardBrands: ['business'],
    supportedCountries: [props.paymentData.userData.country ? props.paymentData.userData.country : "AU"]
  };
  // Get the Stripe Checkout overlay iframe

  const handleSubmit = async (event) => {
    event.preventDefault();
    eventEmitter.emit("processStart")
    if (props.paymentData.userData.fullName === undefined || props.paymentData.userData.fullName === "") {
      NotificationManager.error("Name is Empty", "", NOTIFICATION_DELAY);
      eventEmitter.emit("processStart", "end")
      return;
    }
    if (props.paymentData.userData.emailAddress === undefined || props.paymentData.userData.emailAddress === "") {
      NotificationManager.error("Email Address is Empty", "", NOTIFICATION_DELAY);
      eventEmitter.emit("processStart", "end")
      return;
    }
    if (props.paymentData.userData.country === undefined || props.paymentData.userData.country === "") {
      NotificationManager.error("Country is Empty", "", NOTIFICATION_DELAY);
      eventEmitter.emit("processStart", "end")
      return;
    }
    if (props.paymentData.userData.state === undefined || props.paymentData.userData.state === "") {
      NotificationManager.error("State is Empty", "", NOTIFICATION_DELAY);
      eventEmitter.emit("processStart", "end")
      return;
    }
    if (props.paymentData.userData.postalCode === undefined || props.paymentData.userData.postalCode === "") {
      NotificationManager.error("Postal Code is Empty", "", NOTIFICATION_DELAY);
      eventEmitter.emit("processStart", "end")
      return;
    }
    if (props.paymentData.quantity === 0) {
      NotificationManager.error("Not enough quantity", "", NOTIFICATION_DELAY);
      eventEmitter.emit("processStart", "end")
      return;
    }

    let amount = parseInt(props.paymentData.price * 100) * parseInt(props.paymentData.quantity)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: props.paymentData.userData?.fullName,
        email: props.paymentData.userData?.emailAddress,
        address: {
          line1: props.paymentData.userData?.state + " " + props.paymentData.userData?.country + " " + props.paymentData.userData?.postalCode,
          state: props.paymentData.userData?.state,
          postal_code: props.paymentData.userData?.postalCode,
          country: props.paymentData.userData?.country
        }
      }
    }, {
      amount: amount,
      currency: props.paymentData.currency
    });


    if (!error) {
      console.log('Payment method created:', paymentMethod);
      // send payment method id to server for processing
      let paymentMethodId = paymentMethod.id;
      console.log("props.paymentData.walletAddress", props.paymentData.walletAddress)
      let paymentRequest = {
        "paymentMethodId": paymentMethodId,
        "walletAddress": props.paymentData.walletAddress,
        "contractAddress": props.paymentData.contractAddress,
        "amount": amount,
        "quantity": parseInt(props.paymentData.quantity),
        "currency": props.paymentData.currency,
        "fullName": props.paymentData.userData.fullName,
        "emailAddress": props.paymentData.userData.emailAddress,
        "state": props.paymentData.userData.state,
        "country": props.paymentData.userData.country,
        "postalCode": props.paymentData.userData.postalCode
      };
      let paymentIntent = await createPayment(paymentRequest)
      console.log("paymentIntent", paymentIntent, paymentIntent.status)
      if (paymentIntent.status === 'requires_action') {
        const { next_action } = paymentIntent;
        if (next_action.type === 'redirect_to_url') {
          const { url } = next_action.redirect_to_url;
          window.location.href = url;
        } else if (next_action.type === 'use_stripe_sdk') {
          console.log("paymentIntent.client_secret", paymentIntent.client_secret)
          const client_secret = paymentIntent.client_secret;
          console.log("payment_intent_client_secret", client_secret)
          let paymentStatus = await stripe.confirmCardPayment(client_secret);
          console.log('paymentStatus', paymentStatus, paymentStatus?.paymentIntent?.status);
          if (paymentStatus?.paymentIntent?.status == "succeeded") {
            let newpaymentRequest = { "paymentMethodId": paymentMethodId }
            let paymentIntentrecheck = await updatePayment(newpaymentRequest)
            console.log("paymentIntentrecheck ", paymentIntentrecheck)
            // NotificationManager.success("Payment successful", "", NOTIFICATION_DELAY);
            eventEmitter.emit("processStart", "completed")
            return;
          } else {
            console.log("Credit card error");
            NotificationManager.error(paymentStatus?.error?.message ? paymentStatus.error.message : "Payment Failed", "", NOTIFICATION_DELAY);
            eventEmitter.emit("processStart", "end")
            return;
          }
        }
      } else if (paymentIntent.status === 'succeeded') {
        // NotificationManager.success("Payment successful", "", NOTIFICATION_DELAY);
        eventEmitter.emit("processStart", "completed")
        return;
      }
    } else {
      console.log("Credit card error 1", error.message);
      NotificationManager.error(error.message, "", NOTIFICATION_DELAY);
      eventEmitter.emit("processStart", "end")
      return;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label for="carddetails">Card information</label>
        <CardElement key={props.paymentData.userData.country} options={cardOptions} />
        <button type="submit" className="btn btn-success creditcard">Complete Order</button>
      </form>
      <footer class="App-Footer Footer">
        <div class="Footer-PoweredBy">
          <a class="Link Link--primary" href="https://stripe.com" target="_blank" rel="noopener">
            Powered by&nbsp;&nbsp;
            <svg class="InlineSVG Icon Footer-PoweredBy-Icon Icon--md" focusable="false" width="33" height="15" role="img" aria-labelledby="stripe-title">Stripe<g fill-rule="evenodd"><path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg>
          </a>
        </div>
        <div class="Footer-Links">
          <a class="Link Link--primary" href="https://stripe.com/legal/end-users" target="_blank" rel="noopener"> Terms </a> &nbsp;&nbsp;&nbsp;
          <a class="Link Link--primary" href="https://stripe.com/privacy" target="_blank" rel="noopener"> Privacy</a>
        </div>
      </footer>

    </>
  );
}

export default CheckoutForm;