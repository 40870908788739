

export const DEFAULT_PROJECT_ID = process.env.REACT_APP_PRODUCT_ID;

export const DEFAULT_RELAY_URL = "wss://relay.walletconnect.com";

export const DEFAULT_EIP155_METHODS = ["eth_sendTransaction", "personal_sign", "eth_signTypedData"];

export const DEFAULT_EIP_155_EVENTS = {
  ETH_CHAIN_CHANGED: "chainChanged",
  ETH_ACCOUNTS_CHANGED: "accountsChanged",
}

export const DEFAULT_LOGGER = "debug";

export const DEFAULT_APP_METADATA = {
  name: "React App with ethers",
  description: "React App for WalletConnect",
  url: "https://walletconnect.com/",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};
